<template>
  <CMS>
    <Loader v-if="!layoutResource" />
    <Layout v-else :resource="layoutResource">
      <Blocks :resource="resource" />
    </Layout>
  </CMS>
</template>

<script>
import CMS from '@i22/frontend-cms-utils/components/cms'
import Loader from '@i22/frontend-cms-utils/components/loader'
import pageMixin from '@i22/frontend-cms-utils/mixins/page-mixin'
import Layout from '@/components/layouts/default'
import Blocks from '@/components/blocks'

export default {
  components: {
    CMS,
    Loader,
    Blocks,
    Layout
  },
  mixins: [ pageMixin ],
  transition: {
    afterEnter () {
      this.$root.$emit('scrollAfterEnter')
    }
  },
  validate ({ route, redirect }) {
    if (route.path === '/') {
      return redirect({ ...route, path: '/de' })
    }
    if (route.path.endsWith('/')) {
      return redirect({
        ...route,
        path: route.path.slice(0, -1)
      })
    }
    return true
  },
  computed: {
    layoutResource () {
      return this.resource ? this.resource.currentLanguage.filteredChildren.find(c => c.resource_class_name === 'Layout') : null
    }
  },
  mounted () {
    if (this.$cms) {
      this.notifyRouteChange(this.$route, {
        path: this.$route.path
      })
    } else if (!this.resource) {
      if (this.$route.path.includes('/en')) {
        this.$nuxt.error({ statusCode: 404, message: 'The page you requested could not be found.', lang: 'en' })
      } else {
        this.$nuxt.error({ statusCode: 404, message: 'Die von Ihnen angefordertete Seite konnte leider nicht gefunden werden.', lang: 'de' })
      }
    }
  },
  head () {
    const script = []
    const link = []

    if (!this.$cms && process.env.COOKIEBOT_ID) {
      script.push({
        id: 'Cookiebot',
        src: 'https://consent.cookiebot.com/uc.js',
        'data-cbid': process.env.COOKIEBOT_ID,
        'data-blockingmode': 'auto',
        type: 'text/javascript'
      })
    }

    return {
      link,
      script
    }
  }
}
</script>
