<template>
  <div
    class="page"
    :class="{ 'page--alternative' : resource.columns.layout_alternative}"
  >
    <slot />
  </div>
</template>

<script>
import { Resource } from '@/models'
import matomoPageTracker from '@/mixins/matomo-page-tracker'
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'Page',
  mixins: [ editable, matomoPageTracker ],
  computed: {
    image () {
      const image = Resource.query()
        .where('id', this.resource.columns.file)
        .first()

      if (!image) { return }
      return image.columns.file.url
    }
  },
  mounted () {
    if (process.browser) {
      window.scrollTo(0, 0)
    }
  },
  head () {
    return {
      title: this.resource.columns.meta_title,
      meta: [
        { hid: 'description', name: 'description', content: this.resource.columns.meta_description },
        { hid: 'og-title', property: 'og:title', content: this.resource.columns.meta_og_title },
        { hid: 'og-description', property: 'og:description', content: this.resource.columns.meta_description },
        { hid: 'og-image', property: 'og:image', content: this.image }
      ]
    }
  }
}
</script>
<style lang="scss">
@import '@/scss/ui.scss';
.page {
  background-color: color(light);
  color: color(primary);
  overflow: auto;
  @include breakpoint(sm) {
    overflow: visible;
    padding-right: 50px;
  }

  &--alternative {
    background-color: color(beige);
    color: color(grey);
  }
}
</style>
