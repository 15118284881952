<template>
  <div
    v-if="resource && resource.columns"
    v-in-viewport.once
    class="accordion-item"
  >
    <div
      v-if="resource.columns.title"
      class="accordion-item__title"
      @click="handleActivation"
    >
      <span
        v-editable.text="resource.columns.title"
        v-text="resource.columns.title"
      />
      <ExpandArrowIcon
        :class="{ 'accordion-item__icon--active' : active }"
        class="accordion-item__icon"
      />
    </div>
    <div
      v-if="resource.columns.text"
      ref="content"
      v-editable="resource.columns.text"
      class="accordion-item__content"
    />
  </div>
</template>

<script>
import ExpandArrowIcon from '@/assets/icons/expand-arrow.svg'
import editable from '@i22/frontend-cms-utils/mixins/editable'
export default {
  name: 'AccordionItem',
  components: {
    ExpandArrowIcon
  },
  mixins: [ editable ],
  props: {
    resource: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    active () { this.handleChange() }
  },
  methods: {
    handleActivation () {
      this.$emit('activate', this.resource.$id)
    },
    handleChange () {
      const { content } = this.$refs
      if (this.active) {
        content.style.height = `${content.scrollHeight}px`
        content.style.paddingBottom = '20px'
        return
      }
      content.style.height = '0px'
      content.style.paddingBottom = '0px'
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.accordion-item {
  transition:
    opacity $transition-duration * 2 ease,
    transform $transition-duration * 2 ease;

  &.below-viewport {
    transform: translateY($animation-offset);
    opacity: 0;
  }

  &.in-viewport {
    transform: none;
    opacity: 1;
  }

  &__title {
    @include font-family(light);
    position: relative;
    text-transform: uppercase;
    font-size: font-size(4);
    line-height: line-height(massive);
    color: color(light);
    letter-spacing: 3px;
    padding: 15px 0;
    border-top: 1px solid color(light);
    cursor: url('~assets/icons/pointer_white_s.png') 9.5 9.5, move;
    cursor: -webkit-image-set(url('~assets/icons/pointer_white_s.png') 1x, url('~assets/icons/pointer_white_l.png') 2x) 9.5 9.5, move;
  }

  &:last-child {
    border-bottom: 1px solid color(light);
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    transition: transform $transition-duration ease;

    &--active {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__content {
    @include font-family(light);
    box-sizing: content-box;
    font-size: font-size(4);
    line-height: line-height(massive);
    color: color(light);
    height: 0;
    overflow: hidden;
    transition:
      height $transition-duration * 1.2 ease-in-out,
      padding $transition-duration * 1.2 ease-in-out;
  }
}
</style>
