<template>
  <div class="layered-teaser">
    <div
      class="layered-teaser__column layered-teaser__column--left"
      :class="[
        `layered-teaser__column--${resource.columns.color}`,
        { 'layered-teaser__column--left-active' : hoverLeft }
      ]"
      @mouseenter="hoverLeft = true"
      @mouseleave="hoverLeft = false"
    >
      <div
        v-if="resource.columns.headline_left"
        v-editable.text="resource.columns.headline_left"
        class="layered-teaser__topline"
      />
      <div
        v-if="resource.columns.text_left"
        v-editable="resource.columns.text_left"
        class="layered-teaser__paragraph"
      />
    </div>

    <div
      class="layered-teaser__column layered-teaser__column--right"
      :class="[
        `layered-teaser__column--${resource.columns.color}`,
        { 'layered-teaser__column--right-active' : !hoverLeft }
      ]"
    >
      <div
        v-if="resource.columns.headline_right"
        v-editable.text="resource.columns.headline_right"
        class="layered-teaser__topline"
      />
      <div
        v-if="resource.columns.text_right"
        v-editable="resource.columns.text_right"
        class="layered-teaser__paragraph"
      />
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'LayeredTeaser',
  mixins: [ editable ],
  data () {
    return {
      hoverLeft: false
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.layered-teaser {
  width: 100%;
  max-width: $max-container-width;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding-top: 1px;

  &__column {
    width: 90%;
    color: color(light);
    padding: 60px;
    transition:
      z-index $transition-duration * 0.5 ease,
      transform $transition-duration ease;

    @include breakpoint(sm) {
      padding: 60px;
      width: 66.67%;
    }

    @include breakpoint(lg) {
      padding: 150px 110px;
    }

    &--black {
      background-color: rgba(color(dark), 0.9);
      color: color(grey);
    }

    &--gold {
      background-color: rgba(color(lifestyle), 0.9);
    }

    &--blue {
      background-color: rgba(color(business), 0.9);
    }

    &--green {
      background-color: rgba(color(living), 0.9);
    }

    &--beige {
      background-color: rgba(color(beige), 0.9);
      color: color(grey);
    }

    &--left {
      position: relative;
      margin-top: 120px;
      z-index: 1;

      @include breakpoint(sm) {
        margin-top: 80px;
      }
    }

    &--left-active {
      z-index: 3;
      transform: scale(1.05);
    }

    &--right {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      transform: scale(0.95)
    }

    &--right-active {
      z-index: 3;
      transform: scale(1);
    }
  }

  &__topline {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(airy);
    margin-bottom: 15px;

    @include breakpoint(lg) {
      margin-bottom: 20px;
    }
  }

  &__paragraph {
    @include font-family(light);
    font-size: font-size(5);
    line-height: line-height(standard);

    @include breakpoint(sm) {
      font-size: font-size(8);
    }
    @include breakpoint(lg) {
      font-size: font-size(9);
    }
  }
}
</style>
