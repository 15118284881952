<template>
  <div :class="{ 'section-tile--special': resource.columns.special }" class="section-tile">
    <div
      :class="{ 'section-tile__container--special': resource.columns.special }"
      class="section-tile__container"
    >
      <div
        v-in-viewport.once
        :class="{
          'section-tile__content-column--reverse': resource.columns.reverse,
          'section-tile__content-column--special': resource.columns.special,
        }"
        class="section-tile__content-column"
      >
        <nuxt-link
          :to="linkPath"
          :class="`section-tile__content--${resource.columns.color}`"
          class="section-tile__content"
        >
          <div
            :class="{ 'section-tile__info--special': resource.columns.special }"
            class="section-tile__info"
          >
            <h2
              v-if="resource.columns.topline"
              v-editable.text="resource.columns.topline"
              class="section-tile__topline"
            />
            <div
              v-if="resource.columns.section"
              v-editable.text="resource.columns.section"
              class="section-tile__title"
            />
          </div>
          <div
            v-if="resource.columns.text && resource.columns.special"
            v-editable="resource.columns.text"
            class="section-tile__text"
          />
          <div
            :class="{ 'section-tile__cta--special' : resource.columns.special }"
            class="section-tile__cta"
          >
            <HyperLink
              v-if="resource.columns.cta"
              :to="linkPath"
              :color="resource.columns.special ? 'grey' : 'white'"
              :center="!resource.columns.special"
              tag="span"
            >
              <span
                v-editable.text="resource.columns.cta"
              />
            </HyperLink>
          </div>
        </nuxt-link>
      </div>
      <div
        v-in-viewport.once
        :class="{
          'section-tile__image-column--reverse' : resource.columns.reverse,
          'section-tile__image-column--special' : resource.columns.special,
        }"
        class="section-tile__image-column"
      >
        <video
          v-if="media && isVideo"
          :src="media.file.url"
          class="section-tile__video"
          type="video/mp4"
          loop
          muted
          autoplay
        >
          Your browser does not support the video tag.
        </video>
        <img
          v-else-if="media"
          :src="media.file.url"
          :alt="media.alt"
          class="section-tile__img"
        >
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
import HyperLink from '@/components/blocks/hyper-link'

export default {
  name: 'SectionTile',
  components: {
    HyperLink
  },
  mixins: [ editable ],
  computed: {
    media () {
      // this.resource.columns.picture for backwards compatibility
      const media = this.resource.columns.media || this.resource.columns.picture
      if (this.resource.resolveById(media)) {
        return this.resource.resolveById(media).columns
      } return false
    },
    linkPath () {
      const link = this.resource.columns.link
      if (this.resource.resolveById(link)) {
        return this.resource.resolveById(link).url
      }
      return '/'
    },
    isVideo () {
      return this.media && this.media.file.content_type.includes('video')
    }
  },
  methods: {
    reverse () {
      if (this.resource.columns.special) { return }
      return this.resource.columns.reverse
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

$overlap-desktop: 40px;
$overlap-tablet: 30px;
$overlap-mobile: 50px;

.section-tile {
  overflow: hidden;
  position: relative;
  z-index: 1;

  &--special {
    padding-bottom: 0;
  }

  &__container {
    @include container();
    flex-direction: column;
    position: relative;

    @include breakpoint(sm) {
      padding: $overlap-tablet 0 0;

      &--special {
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    @include breakpoint(lg) {
      padding: $overlap-desktop 0 0;
    }

    @include breakpoint(xl) {
      overflow: hidden;
      &--special {
        margin: 0 auto;
      }
    }
  }

  &__image-column {
    @include column(12);
    margin-left: -(offset(xs) + $gutter);
    transition:
      opacity $transition-duration * 2 ease,
      transform $transition-duration * 2 ease;

    &.below-viewport {
      transform: translateY($animation-offset);
      opacity: 0;
    }

    &.in-viewport {
      transform: none;
      opacity: 1;
    }

    &--reverse {
      margin-right: -(offset(xs) + $gutter);
      margin-left: auto;
    }

    &--special {
      width: calc(100% + #{2 * offset(xs)});
      margin-right: -(offset(xs));
      margin-left: -(offset(xs));
      padding: 0;
      order: 1;
    }

    @include breakpoint(sm) {
      @include column(11);
      margin-left: -(offset(sm) + $gutter);

      &--reverse {
        @include push(2);
        margin-right: -(offset(sm) + $gutter);
      }

      &--special {
        @include column(12);
        margin-left: 0;
      }
    }

    @include breakpoint(xl) {
      @include column(10);
      padding: 0;
      margin-left: 0;

      &--reverse {
        @include push(2);
        margin-right: 0;
      }

      &--special {
        @include column(12);
        padding: 0;
      }
    }
  }

  &__content-column {
    z-index: 2;
    width: calc(100% + #{offset(xs)});
    margin-right: -(offset(xs));
    margin-bottom: -$overlap-mobile;
    transition:
      opacity $transition-duration * 2 ease $transition-duration,
      transform $transition-duration * 2 ease $transition-duration;

    &.below-viewport {
      transform: translateX($animation-offset);
      opacity: 0;
    }

    &.in-viewport {
      transform: none;
      opacity: 1;
    }

    &--reverse {
      margin-right: auto;
      margin-left: -(offset(xs));

      &.below-viewport {
        transform: translateX(-$animation-offset);
        opacity: 0;
      }

      &.in-viewport {
        transform: none;
        opacity: 1;
      }
    }

    &--special {
      width: calc(100% + #{2 * offset(xs)});
      order: 2;
      margin: 0 #{-(offset(xs))};

      &.below-viewport {
        transform: translateY($animation-offset);
        opacity: 0;
      }

      &.in-viewport {
        transform: none;
        opacity: 1;
      }
    }

    @include breakpoint(sm) {
      @include column(6);
      position: absolute;
      top: 0;
      right: -(offset(sm) + $gutter);
      height: calc(100% - #{2 * $overlap-tablet});
      margin-right: auto;
      margin-bottom: 0;

      &--reverse {
        right: auto;
        left: -(offset(sm) + $gutter);
      }

      &--special {
        position: relative;
        @include column(12);
        height: calc(100% - #{$overlap-tablet});
        right: auto;
        left: 30px;
      }
    }

    @include breakpoint(md) {
      &--special {
        position: absolute;
        @include column(9);
      }
    }

    @include breakpoint(lg) {
      right: (offset(sm) + $gutter) * -1;
      height: calc(100% - #{$overlap-desktop});

      &--special {
        @include column(6);
        min-height: 65%;
        max-height: 80%;
        left: 180px;
      }

      &--reverse {
        right: auto;
        left: (offset(sm) * -1);
      }
    }

    @include breakpoint(xl) {
      right: 0;
      padding: 0;

      &--reverse {
        right: auto;
        left: 0;
      }
    }
  }

  &__text {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
    margin-bottom: 30px;

    @include breakpoint(lg) {
      margin-bottom: 50px;
    }
  }

  &__content {
    display: block;
    padding-top: $overlap-mobile;
    padding-bottom: 30px;
    text-align: center;
    color: color(light);
    text-decoration: none;

    @include breakpoint(sm) {
      width: 100%;
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }

    &--gold {
      background-color: rgba(color(lifestyle), 0.95);
    }

    &--blue {
      background-color: rgba(color(business), 0.95);
    }

    &--green {
      background-color: rgba(color(living), 0.95);
    }

    &--beige {
      padding: 75px 30px 50px;
      background-color: color(beige);
      color: color(grey);
      display: flex;
      flex-direction: column;
      text-align: left;

      @include breakpoint(sm) {
        padding: 75px 50px 50px;
      }
    }
  }

  &__info {
    margin-bottom: 45px;

    @include breakpoint(sm) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      &--special {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        flex: 1;
      }
    }
  }

  &__cta {
    @include breakpoint(sm) {
      position: absolute;
      bottom: 50px;
      left: 50%;
      width: calc(100% - 20px);
      transform: translateX(-50%);

      &--special {
        position: relative;
        transform: none;
        width: auto;
        bottom: auto;
        left: auto;
      }
    }
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__video {
    display: block;
    width: 100%;
  }

  &__topline {
    @include font-family(light);
    font-size: font-size(1);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    margin-bottom: 5px;

    @include breakpoint(sm) {
      font-size: font-size(2);
      letter-spacing: letter-spacing(loose);
    }
  }

  &__title {
    @include font-family(bold);
    font-size: font-size(10);
    line-height: line-height(standard);
    letter-spacing: letter-spacing(solid);
    text-transform: uppercase;

    @include breakpoint(sm) {
      font-size: font-size(11);
      letter-spacing: letter-spacing(airy);
    }
    @include breakpoint(lg) {
      font-size: font-size(13);
      letter-spacing: letter-spacing(loose);
    }
  }
}
</style>
