<template>
  <div
    v-if="resource && resource.columns"
    class="footer"
  >
    <div class="footer__container">
      <div class="footer__column-copyright">
        <div
          v-if="resource.columns.copyright"
          v-editable.text="resource.columns.copyright"
          class="footer__copyright"
        />
      </div>
      <div class="footer__column-info">
        <div
          v-if="resource.columns.contact"
          v-editable="resource.columns.contact"
          class="footer__info"
        />
      </div>
      <div class="footer__column-categories">
        <ul class="footer__categories">
          <li
            v-for="category in resource.mainCategories"
            :key="category.id"
          >
            <nuxt-link
              :to="category.url"
              class="footer__link"
              v-text="category.columns.title"
            />
          </li>
        </ul>
      </div>
      <div class="footer__column-links">
        <ul class="footer__links">
          <li
            v-for="link in resource.secondaryCategories"
            :key="link.id"
          >
            <nuxt-link
              :to="link.url"
              class="footer__link"
              v-text="link.columns.title"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
export default {
  name: 'Footer',
  mixins: [ editable ]
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.footer {
  position: relative;
  z-index: 1;
  background: #000;
  cursor: url('~assets/icons/default_white_s.png') 7.5 7.5, move;
  cursor: -webkit-image-set(
    url('~assets/icons/default_white_s.png') 1x,
    url('~assets/icons/default_white_l.png') 2x
  ) 7.5 7.5, move;

  &__container {
    @include container();
    @include font-family(light);
    font-size: font-size(2);
    line-height: line-height(massive);
    color: color(light);
    letter-spacing: 1px;
    padding: 45px 0 60px;
  }

  &__column-copyright {
    @include column(12);
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  &__column-info {
    @include column(12);
    margin-bottom: 20px;

    @include breakpoint(md) {
      @include column(7);
      margin-bottom: 0;
    }
  }

  &__info {
    margin: 0;
    padding: 0;
    word-spacing: 15px;
    text-transform: uppercase;

    p {
      margin: 0;
    }
  }

  &__column-categories {
    @include column(12);
    margin-bottom: 20px;

    @include breakpoint(sm) {
      @include column(6);
      margin-bottom: 0;
    }

    @include breakpoint(md) {
      @include column(2);
    }
  }

  &__categories {
    text-transform: uppercase;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__column-links {
    @include column(12);

    @include breakpoint(sm) {
      @include column(6);
      display: flex;
      align-items: flex-end;
    }

    @include breakpoint(md) {
      @include column(3);
      display: flex;
    }
  }

  &__link {
    color: color(light);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__links {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.layout .footer a {
  cursor: url('~assets/icons/pointer_white_s.png') 9.5 9.5, move;
  cursor: -webkit-image-set(url('~assets/icons/pointer_white_s.png') 1x, url('~assets/icons/pointer_white_l.png') 2x) 9.5 9.5, move;
}
</style>
