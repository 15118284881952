<template>
  <div class="large-image">
    <div class="large-image__container">
      <div
        class="large-image__column"
        :class="`large-image__column--${resource.columns.alignment}`"
      >
        <img
          v-if="image"
          :src="`${image.file.url}`"
          :alt="image.alt"
          class="large-image__img"
        >
        <div
          v-if="image && resource.columns.show_description"
          class="large-image__description"
          v-text="image.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'LargeImage',
  mixins: [ editable ],
  computed: {
    image () {
      const image = this.resource.columns.image
      if (!this.resource.resolveById(image)) { return null }
      return this.resource.resolveById(image).columns
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.large-image {
  position: relative;
  z-index: 1;

  &__container {
    @include container();
    margin: 0;

    @include breakpoint(sm) {
      margin: 0;
    }
  }

  &__column {
    width: 100%;

    @include breakpoint(sm) {
      @include column(12);
      padding-right: 0;
    }

    @include breakpoint(md) {
      &--center {
        @include column(10);
        @include push(1);
      }

      &--left {
        @include column(11);
        padding-left: 0;
      }

      &--right {
        @include column(11);
        @include push(1);
        padding-right: 0;
      }
    }
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__description {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
    margin: 5px 30px 0;
    margin-top: 5px;

    @include breakpoint(sm) {
      margin: 5px 0 0;
    }
  }
}
</style>
