<template>
  <div v-if="resource && resource.columns" ref="stage" class="stage">
    <div
      ref="stage"
      :style="[ background && !isVideo ? { backgroundImage: `url(${fileUrl})`} : {}]"
      :class="{
        'stage__wrapper--noimage' : !background,
        'stage__wrapper--noteaser' : !resource.columns.teaser_topline && !resource.columns.teaser_text
      }"
      class="stage__wrapper"
    >
      <div class="stage__container stage__container--align-bottom">
        <div
          :class="headlineColor"
          class="stage__column"
        >
          <div
            v-if="resource.columns.topline"
            v-editable.text="resource.columns.topline"
            class="stage__topline"
          />
          <h1
            v-if="resource.columns.title"
            v-editable.text="resource.columns.title"
            class="stage__headline"
          />
        </div>
      </div>
      <div
        ref="video"
        class="stage__video-wrapper"
      >
        <video
          v-if="background && isVideo"
          :src="fileUrl"
          class="stage__video"
          type="video/mp4"
          loop
          muted
          autoplay
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <div class="stage__teaser">
      <div class="stage__teaser-container">
        <div
          :class="`stage__teaser-column--${resource.columns.color}`"
          class="stage__teaser-column"
        >
          <div class="stage__teaser-content">
            <h2
              v-if="resource.columns.teaser_topline"
              v-editable.text="resource.columns.teaser_topline"
              class="stage__teaser-topline"
            />
            <div
              v-if="resource.columns.teaser_text"
              v-editable="resource.columns.teaser_text"
              class="stage__teaser-text"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isVideo" ref="background" :class="{'stage__background--alternative' : alternativeLayout}" class="stage__background" />
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'Stage',
  mixins: [ editable ],
  data () {
    return {
      fixedBackground: false
    }
  },
  computed: {
    background () {
      // this.resource.columns.image for backwards compatibility
      const background = this.resource.columns.background || this.resource.columns.image
      if (!background) { return null }
      return this.resource.resolveById(background)
    },
    fileUrl () {
      return this.background ? this.background.columns.file.url : null
    },
    isVideo () {
      return this.background && !!this.background.columns.file.content_type.includes('video')
    },
    headlineColor () {
      const headlineColor = this.resource.columns.headline_color
      if (!headlineColor) { return null }
      return `stage__column--${headlineColor}`
    },
    alternativeLayout () {
      const page = this.resource && this.resource.currentPage
      return page && page.columns && page.columns.layout_alternative
    }
  },
  mounted () {
    if (process.browser) {
      window.addEventListener('scroll', this.handleScroll, false)
      this.handleScroll()
    }
  },
  beforeDestroy () {
    if (process.browser) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    handleScroll () {
      if (this.isVideo) { this.toggleBackgroundClass() }
    },
    toggleBackgroundClass (e) {
      const stage = this.$refs.stage
      const bg = this.$refs.background
      const video = this.$refs.video

      if (!stage) { return };

      const topDistance = stage.getBoundingClientRect().top * -1
      const height = stage.clientHeight

      video.style.transform = `translateY(${window.scrollY * -0.3}px)`

      if (topDistance >= height && !this.fixedBackground) {
        this.fixedBackground = true
        bg.classList.add('stage__background--fixed')
      }
      if (topDistance + 50 <= height && this.fixedBackground) {
        this.fixedBackground = false
        bg.classList.remove('stage__background--fixed')
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.stage  {
  @include breakpoint(sm) {
    margin-right: -50px;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    height: 75vh;
    overflow: hidden;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: color(light);

    &--noimage {
      z-index: 1;
      position: relative;
      padding-top: 50px;
      height: auto;
      color: color(primary);
    }

    @include breakpoint(sm) {
      height: 100vh;

      &--noteaser {
        height: 95vh;
      }

      &--noimage {
        height: auto;
      }
    }
  }

  &__container {
    @include container();
    align-self: flex-end;

    @include breakpoint(xl) {
      width: 100%;
    }
  }

  &__column {
    @include column(12);
    z-index: 2;
    margin-bottom: 100px;
    animation-name: stageColumnAppear;
    animation-duration: 2s;
    animation-iteration-count: 1;

    @keyframes stageColumnAppear {
      0% { opacity: 0; }
      80% { opacity: 0; }
      100%   { opacity: 1; }
    }

    &--black {
      color: color(primary)
    }

    &--white {
      color: color(light)
    }
  }

  &__topline {
    @include font-family(regular);
    font-size: font-size(2);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-left: -15px;
    text-shadow: 0 0 20px hsla(0, 0%, 100%, 0.8);

    @include breakpoint(sm) {
      letter-spacing: letter-spacing(loose);
      margin-bottom: 20px;
      margin-left: 0;
    }
  }

  &__headline {
    @include font-family(bold);
    font-size: font-size(9);
    line-height: line-height(solid);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    margin-left: -15px;
    text-shadow: 0px 0px 15px rgba(255,255,255,0.1);

    @include breakpoint(sm) {
      font-size: font-size(14);
      letter-spacing: letter-spacing(loose);
      margin-left: -0.05em;
    }

    @include breakpoint(lg) {
      font-size: font-size(15);
      line-height: line-height(tight);
      letter-spacing: letter-spacing(massive);
    }
  }

  &__teaser-column {
    @include column(12);
    z-index: 3;
    background-color: rgba(1, 1, 1, 0);
    animation-name: stageTeaserAppear;
    animation-duration: 3s;
    animation-iteration-count: 1;

    @keyframes stageTeaserAppear {
      0% { transform: translateY(70px); }
      80% { transform: translateY(70px); }
      100%   { transform: translateY(0px); }
    }

    &--gold {
      background-color: rgba(color(lifestyle), 0.9);
    }

    &--green {
      background-color: rgba(color(living), 0.9);
    }

    &--blue {
      background-color: rgba(color(business), 0.9);
    }

    &--beige {
      background-color: rgba(color(beige), 0.9);
    }

    @include breakpoint(sm) {
      @include column(11);
    }
  }

  &__teaser-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1640px;
  }

  &__teaser-content {
    color: color(light);
    padding: 25px;

    @include breakpoint(sm) {
      padding: 50px;
    }

    @include breakpoint(lg) {
      padding: 100px 100px 100px 150px;
    }
  }

  &__teaser {
    margin-top: -50px;
  }

  &__teaser-topline {
    @include font-family(light);
    font-size: font-size(2);
    line-height: line-height(massive);
    text-transform: uppercase;
    letter-spacing: letter-spacing(loose);
    margin-bottom: 15px;
  }

  &__teaser-text {
    @include font-family(light);
    font-size: font-size(5);
    line-height: line-height(solid);

    @include breakpoint(sm) {
      margin-left: -0.05em;
      font-size: font-size(8);
      line-height: line-height(standard);
    }

    @include breakpoint(lg) {
      font-size: font-size(9);
    }
  }

  &__background {
    position: absolute;
    top: 75%;
    left: 0;
    width: 100%;
    height: 150vh;
    background-color: color(light);

    @include breakpoint(sm) {
      top: 100%;
    }

    &--alternative {
      background-color: #fff6ea;
    }

    &--fixed {
      position: fixed;
      top: 0px;
      height: 100vh;
    }
  }

  &__video-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75vh;
    overflow: hidden;

    @include breakpoint(sm) {
      height: 100vh;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%);
    background-color: #eee;

    @media (min-aspect-ratio: 16/9) {
      height: auto;
      width: 100%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}
</style>
