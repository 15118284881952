<template>
  <div class="small-text">
    <div class="small-text__container">
      <div class="small-text__column">
        <div
          v-if="resource.columns.headline"
          v-editable.text="resource.columns.headline"
          class="small-text__headline"
        />
        <div
          v-if="resource.columns.text"
          v-editable="resource.columns.text"
          class="small-text__paragraph"
        />
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'SmallText',
  mixins: [ editable ]
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.small-text {
  position: relative;
  z-index: 1;

  &__container {
    @include container();
  }

  &__column {
    @include column(12);

    @include breakpoint(sm) {
      @include column(10);
      @include push(1);
    }

    @include breakpoint(lg) {
      @include column(6);
      @include push(3);
    }
  }

  &__headline {
    @include font-family(bold);
    font-size: font-size(6);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    margin-bottom: 20px;

    @include breakpoint(sm) {
      font-size: font-size(7);
      margin-bottom: 15px;
    }

    @include breakpoint(lg) {
      font-size: font-size(9);
      margin-bottom: 20px;
    }
  }

  &__paragraph {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
  }
}
</style>
