<template>
  <div class="column-text">
    <div class="column-text__container">
      <div
        v-in-viewport.once
        class="column-text__headline-column"
      >
        <h3
          v-if="resource.columns.headline"
          v-editable.text="resource.columns.headline"
          class="column-text__headline"
        />
      </div>
      <div
        v-in-viewport.once
        class="column-text__paragraph-column"
      >
        <div
          v-if="resource.columns.paragraph"
          v-editable="resource.columns.paragraph"
          class="column-text__paragraph"
        />
        <HyperLink
          v-if="resource.columns.cta"
          :to="linkPath"
          :color="resource.columns.color"
          class="column-text__link"
        >
          <span
            v-editable.text="resource.columns.cta"
          />
        </HyperLink>
      </div>
    </div>
  </div>
</template>

<script>
import HyperLink from '@/components/blocks/hyper-link'
import editable from '@i22/frontend-cms-utils/mixins/editable'
import linkPathMixin from '@/mixins/link-path'

export default {
  name: 'ColumnText',
  components: {
    HyperLink
  },
  mixins: [ editable, linkPathMixin ]
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.column-text {
  position: relative;
  z-index: 1;

  &__container {
    @include container();
  }

  &__headline-column {
    @include column(12);
    transition:
      opacity $transition-duration * 2 ease,
      transform $transition-duration * 2 ease;

    &.below-viewport {
      transform: translateY($animation-offset);
      opacity: 0;
    }

    &.in-viewport {
      transform: none;
      opacity: 1;
    }

    @include breakpoint(sm) {
      @include column(10);
      @include push(1);
    }

    @include breakpoint(md) {
      @include column(5);
    }
  }

  &__paragraph-column {
    @include column(12);
    transition:
      opacity $transition-duration * 2 ease $transition-duration / 2,
      transform $transition-duration * 2 ease $transition-duration / 2;

    &.below-viewport {
      transform: translateY($animation-offset);
      opacity: 0;
    }

    &.in-viewport {
      transform: none;
      opacity: 1;
    }

    @include breakpoint(sm) {
      @include column(10);
      @include push(1);
    }

    @include breakpoint(md) {
      @include column(4);
    }
  }

  &__headline {
    @include font-family(regular);
    font-size: font-size(5);
    line-height: line-height(standard);
    margin-bottom: 45px;

    @include breakpoint(sm) {
      font-size: font-size(7);
      letter-spacing: letter-spacing(tight);
      margin-bottom: 35px;
    }

    @include breakpoint(lg) {
      margin-bottom: 0;
      font-size: font-size(9);
    }
  }

  &__paragraph {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
  }

  &__link {
    margin-top: 20px;
  }
}
</style>
