<template>
  <CMSComponent v-if="resource" :resource="resource" :class="spacingClass">
    <component :is="blockName" :resource="resource">
      <template v-if="filteredChildren && filteredChildren.length">
        <Blocks v-for="child in filteredChildren" :key="child.id" :resource="child" />
      </template>
    </component>
  </CMSComponent>
</template>

<script>
import blocksMixin from '@i22/frontend-cms-utils/mixins/blocks-mixin'
import CMSComponent from "@i22/frontend-cms-utils/components/cms-component";
import Unknown from '@i22/frontend-cms-utils/components/unknown'
import spacing from '@/mixins/spacing'
import Page from '@/components/blocks/page'
import VideoStage from '@/components/blocks/video-stage'
import LargeText from '@/components/blocks/large-text'
import SectionTile from '@/components/blocks/section-tile'
import ColumnText from '@/components/blocks/column-text'
import Slider from '@/components/blocks/slider'
import Gallery from '@/components/blocks/gallery'
import SectionTeaser from '@/components/blocks/section-teaser'
import Accordion from '@/components/blocks/accordion'
import Stage from '@/components/blocks/stage'
import ImageTeaser from '@/components/blocks/image-teaser'
import Headline from '@/components/blocks/headline'
import ImageTeaserSmall from '@/components/blocks/image-teaser-small'
import List from '@/components/blocks/list'
import InfoBox from '@/components/blocks/info-box'
import GallerySmall from '@/components/blocks/gallery-small'
import LargeImage from '@/components/blocks/large-image'
import LegalText from '@/components/blocks/legal-text'
import SmallText from '@/components/blocks/small-text'
import LayeredTeaser from '@/components/blocks/layered-teaser'

export default {
  name: 'Blocks',
  components: {
    CMSComponent,
    Unknown,
    Page,
    VideoStage,
    LargeText,
    SectionTile,
    Slider,
    ColumnText,
    Gallery,
    SectionTeaser,
    Accordion,
    Stage,
    ImageTeaser,
    Headline,
    ImageTeaserSmall,
    List,
    InfoBox,
    GallerySmall,
    LargeImage,
    LegalText,
    SmallText,
    LayeredTeaser
  },
  mixins: [ blocksMixin, spacing ],
  computed: {
    filteredChildren () {
      return this.resource.filteredChildren.filter(resource =>
          resource.resource_class_name !== 'Page' &&
          resource.resource_class_name !== 'Asset' &&
          resource.resource_class_name !== 'MediaReference'
        );
    },
  }
}
</script>
