<template>
  <div class="image-teaser-small">
    <div
      v-if="color"
      :class="`image-teaser-small__container--${color}`"
      class="image-teaser-small__container"
    >
      <div
        v-in-viewport.once
        :class="{'image-teaser-small__image-column--revert' : resource.columns.revert}"
        class="image-teaser-small__image-column"
      >
        <img
          v-if="image && image.file"
          :src="image.file.url"
          :alt="image.alt"
          class="image-teaser-small__image"
        >
        <div
          v-if="color"
          :class="`image-teaser-small__image-bg--${color}`"
          class="image-teaser-small__image-bg"
        />
      </div>
      <div
        v-in-viewport.once
        :class="{'image-teaser-small__teaser-column--revert' : resource.columns.revert}"
        class="image-teaser-small__teaser-column"
      >
        <div
          v-if="color"
          :class="[
            `image-teaser-small__teaser--${color}`,
            { 'image-teaser-small__teaser--revert' : resource.columns.revert }
          ]"
          class="image-teaser-small__teaser"
        >
          <h3
            v-if="resource.columns.headline"
            v-editable.text="resource.columns.headline"
            class="image-teaser-small__headline"
          />
          <div
            v-if="resource.columns.text"
            v-editable="resource.columns.text"
            class="image-teaser-small__text"
          />
          <div class="image-teaser-small__link-wrapper">
            <HyperLink
              v-if="resource.columns.cta"
              :to="linkPath"
              :color="color"
            >
              {{ resource.columns.cta }}
            </HyperLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
import linkPathMixin from '@/mixins/link-path'
import HyperLink from '@/components/blocks/hyper-link'

export default {
  name: 'ImageTeaserSmall',
  components: {
    HyperLink
  },
  mixins: [ editable, linkPathMixin ],
  computed: {
    color () {
      if (!this.resource.columns.color) { return null }
      return this.resource.columns.color
    },
    image () {
      const image = this.resource.columns.picture
      if (!this.resource.resolveById(image)) { return false }
      return this.resource.resolveById(image).columns
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.image-teaser-small {
  display: block;
  position: relative;
  text-decoration: none;
  z-index: 1;

  &__container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: $max-container-width;
    margin: 0 auto;
    padding: 40px offset(xs);

    &--gold {
      background-color: rgba(color(lifestyle), 0.1);
    }

    &--green {
      background-color: rgba(color(living), 0.1);
    }

    &--blue {
      background-color: rgba(color(business), 0.1);
    }

    &--beige {
      background-color: rgba(color(beige), 0.1);
    }

    @include breakpoint(sm) {
      background-color: color(light);
      padding: 0;
    }

    &:hover {
      .image-teaser-small__image-bg {
        top: $gutter * 2;
        right: $gutter * 3;
      }

      .image-teaser-small__image-column {
        transform: translateY(-#{$gutter}) translateX(#{$gutter});
      }
    }
  }

  &__image-column {
    @include column(12);
    position: relative;
    margin-bottom: 50px;
    transition: transform $transition-duration ease;
    z-index: 1;
    transition:
      opacity $transition-duration * 2 ease,
      transform $transition-duration * 2 ease;

    &.below-viewport {
      transform: translateX($animation-offset);
      opacity: 0;
    }

    &.in-viewport {
      transform: none;
      opacity: 1;
    }

    @include breakpoint(sm) {
      @include column(6);
      @include push(6);
      margin-top: 105px;
      margin-bottom: 105px;

      &--revert {
        margin-left: 0;
      }
    }

    @include breakpoint(lg) {
      @include column(7);
      margin-top: 120px;
      margin-bottom: 120px;
    }

    @include breakpoint(xl) {
      @include column(6);
    }
  }

  &__image-bg {
    position: absolute;
    width: calc(100% - #{$gutter} * 2);
    height: 100%;
    top: 0;
    right: 10px;
    z-index: 0;
    transition:
      top $transition-duration * 2 ease,
      right $transition-duration * 2 ease;

    @include breakpoint(xl) {
      width: calc(100% - #{$gutter} * 2);
      right: $gutter * 3;
    }

    &--gold {
      background-color: color(lifestyle);
    }

    &--green {
      background-color: color(living);
    }

    &--blue {
      background-color: color(business);
    }

    &--beige {
      background-color: color(beige);
    }
  }

  &__teaser-column {
    @include column(12);
    transition:
      opacity $transition-duration * 2 ease,
      transform $transition-duration * 2 ease;

    &.below-viewport {
      transform: translateY($animation-offset);
      opacity: 0;
    }

    &.in-viewport {
      transform: none;
      opacity: 1;
    }

    @include breakpoint(sm) {
      @include column(10);
      position: absolute;
      top: 0;
      height: 100%;

      &--revert {
        @include push(2);
        padding: 0;
      }
    }

    @include breakpoint(lg) {
      @include column(10);
      &--revert {
        padding: 0;
      }
    }

    @include breakpoint(xl) {
      @include column(10);
    }
  }

  &__teaser {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include breakpoint(sm) {
      padding: 100px 45% 100px #{offset(sm) - $gutter};

      &--gold {
        background-color: rgba(color(lifestyle), 0.1);
      }

      &--green {
        background-color: rgba(color(living), 0.1);
      }

      &--blue {
        background-color: rgba(color(business), 0.1);
      }

      &--beige {
        background-color: rgba(color(beige), 0.1);
      }

      &--revert {
        padding: 100px #{offset(sm) - $gutter} 100px 45%;
      }
    }

    @include breakpoint(lg) {
      padding: 115px 55% 115px #{offset(sm) - $gutter};

      &--revert {
        padding: 115px #{offset(sm) - $gutter} 115px 55%;
      }
    }
  }

  &__headline {
    flex: 1;
    @include font-family(bold);
    font-size: font-size(8);
    line-height: line-height(solid);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    margin-bottom: 30px;

    @include breakpoint(sm) {
      font-size: font-size(9);
      margin-bottom: 20px;
    }

    @include breakpoint(lg) {
      font-size: font-size(12);
      margin-bottom: 40px;
    }
  }

  &__text {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(solid);
    margin-bottom: 30px;
  }

  &__image {
    position: relative;
    display: block;
    width: 100%;
    z-index: 1;
  }
}
</style>
