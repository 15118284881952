<template>
  <div
    v-in-viewport.once
    class="slider"
  >
    <div v-if="images" ref="slider" class="slider__boundaries">
      <div ref="container" class="slider__container">
        <img
          v-for="(slide, index) in images"
          ref="slide"
          :key="index"
          :src="images[index].columns.file.url"
          :alt="images[index].columns.alt"
          :class="{ 'slider__slide--large' : large }"
          class="slider__slide"
        >
      </div>
      <div
        ref="bar"
        :class="handleColor('slider__bar')"
        class="slider__bar"
      >
        <div
          ref="handle"
          :class="handleColor('slider__bar-handle')"
          class="slider__bar-handle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { TweenMax } from 'gsap'
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'Slider',
  mixins: [ editable ],
  data () {
    return {
      oldSlide: 0,
      activeSlide: 0,
      sliderWidth: 0,
      offsets: [],
      slider: [],
      siteOffset: 50
    }
  },
  computed: {
    large () {
      if (!this.resource.columns.large) { return false }
      return this.resource.columns.large
    },
    images () {
      const slides = this.resource.columns.slides ? this.resource.columns.slides : []
      if (this.resource.resolveByIds(slides).length === 0) { return false }
      return this.resource.resolveByIds(slides)
    }
  },
  watch: {
    resource () {
      window.setTimeout(this.setContainerWidthAndOffsets, 800)
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setContainerWidthAndOffsets)
  },
  methods: {
    init () {
      if (process.browser && this.images) {
        const Draggable = require('gsap/Draggable').Draggable
        require('@/plugins/vendor/ThrowPropsPlugin')
        this.slider = Draggable.create(this.$refs.container, {
          type: 'x',
          edgeResistance: 1,
          snap: [],
          throwProps: true,
          onDrag: this.updateHandle,
          onThrowUpdate: this.onCompleteHandle,
          onDragEnd: this.slideAnimation,
          allowNativeTouchScrolling: false,
          zIndexBoost: false
        })

        window.setTimeout(this.setContainerWidthAndOffsets, 200)
        window.addEventListener('resize', this.setContainerWidthAndOffsets)
      }
    },
    setContainerWidthAndOffsets () {
      this.offsets = []
      this.sliderWidth = 0
      this.siteOffset = window.innerWidth > 768 ? 150 : 50
      for (const item in this.$refs.slide) {
        this.sliderWidth += (this.$refs.slide[item].offsetWidth)
        this.offsets = [...this.offsets, this.$refs.slide[item].offsetLeft * -1]
      }
      TweenMax.set(this.$refs.container, { width: this.sliderWidth, x: this.offsets[this.activeSlide] })
      TweenMax.set(this.$refs.handle, { width: `${100 / this.$refs.slide.length}%` })
      const minBoundary = (this.sliderWidth - window.innerWidth + this.siteOffset) * -1
      this.slider[0].applyBounds({ maxX: 0, minX: minBoundary })
      this.offsets.push(minBoundary)
      this.slider[0].vars.snap = this.offsets
    },
    updateHandle () {
      const scrollPercentage = this.slider[0].x * -1 / (this.sliderWidth - window.innerWidth + this.siteOffset)
      TweenMax.to(this.$refs.handle, 0.2, { left:
        `calc(${scrollPercentage * 100}% - ${this.$refs.handle.offsetWidth * scrollPercentage}px`
      })
    },
    onCompleteHandle () {
      const scrollPercentage = this.slider[0].x * -1 / (this.sliderWidth - window.innerWidth + this.siteOffset)
      TweenMax.to(this.$refs.handle, 0.1, { left:
        `calc(${scrollPercentage * 100}% - ${this.$refs.handle.offsetWidth * scrollPercentage}px`
      })
    },
    handleColor (className) {
      if (!this.resource.columns.color) { return '' }
      return `${className}--${this.resource.columns.color}`
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.slider {
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition:
    opacity $transition-duration * 2 ease,
    transform $transition-duration * 2 ease;

  &.below-viewport {
    transform: translateY($animation-offset);
    opacity: 0;
  }

  &.in-viewport {
    transform: none;
    opacity: 1;
  }

  &__boundaries {
    width: 100%;
    max-width: $max-container-width;
    position: relative;
    margin-left: offset(xs);

    @include breakpoint(sm) {
      margin-left: offset(sm);
    }

    @include breakpoint(xl) {
      margin: 0 auto;
    }
  }

  &__container {
    display: flex;
  }

  &__slide {
    display: block;
    height: 320px;
    padding-right: 30px;

    &:last-child {
      padding-right: 0;
    }

    cursor: url('~assets/icons/drag-icon.png') 32 32, move;
    cursor: -webkit-image-set(
      url('~assets/icons/drag-icon.png') 1x,
      url('~assets/icons/drag-icon-2x.png') 2x
    ) 32 32, pointer;

    @include breakpoint(sm) {
      height: 420px;
    }

    &--large {
      height: 80vh;
    }
  }

  &__bar {
    position: relative;
    margin-top: 20px;
    width: calc(100% - 100px);
    height: 2px;
    background-color: rgba(color(lifestyle), 0.2);

    &--blue {
      background-color: rgba(color(business), 0.2);
    }

    &--green {
      background-color: rgba(color(living), 0.2);
    }

    &--beige {
      background-color: rgba(color(grey), 0.2);
    }

    &--black {
      background-color: rgba(color(primary), 0.2);
    }
  }

  &__bar-handle {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: color(lifestyle);
    opacity: 1;

    &--blue {
      background-color: color(business);
    }

    &--green {
      background-color: color(living);
    }

    &--beige {
      background-color: color(grey);
    }

    &--black {
      background-color: color(primary);
    }
  }
}
</style>
