<template>
  <div
    :class="{ 'layout--cms' : cmsEnabled }"
    class="layout"
  >
    <div
      :class="{
        'page-wrapper--cms' : cmsEnabled,
        'page-wrapper--navigation' : navigationIsOpen,
      }"
      class="page-wrapper"
    >
      <CookieConsent :resource="getResource('CookieConsent')" />
      <slot />
      <Footer :resource="getResource('Footer')" />
    </div>
    <Navigation
      :open="navigationIsOpen"
      :resource="getResource('Navigation')"
      @toggle="toggleNav"
    />
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
import Footer from '@/components/globals/footer'
import Navigation from '@/components/globals/navigation'
import CookieConsent from '@/components/blocks/cookie-consent'
import { Resource } from '@/models'
import withNavigation from '@/mixins/with-navigation'

export default {
  components: {
    Footer,
    Navigation,
    CookieConsent
  },
  mixins: [ editable, withNavigation ],
  data () {
    return {
      navigationIsOpen: false
    }
  },
  computed: {
    cmsEnabled () {
      return !!this.$cms
    }
  },
  methods: {
    getResource (resourceClass) {
      const resource = Resource.query()
        .whereIdIn(this.resource.children_ids)
        .where('resource_class_name', resourceClass)
        .first()
      return resource
    },
    toggleNav () {
      this.navigationIsOpen = !this.navigationIsOpen
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/app.scss';
@import '@i22/frontend-cms-utils/scss/inline-editor.scss';

.tox .tox-pop {
  min-width: 50px;
}

.layout {
  cursor: url('~assets/icons/default_s.png') 7.5 7.5, move;
  cursor: -webkit-image-set(
    url('~assets/icons/default_s.png') 1x,
    url('~assets/icons/default_l.png') 2x
  ) 7.5 7.5, move;

  &--cms {
    cursor: auto;
  }

  a {
    @include cursorPointer();
  }

  .cms-component a {
    cursor: pointer;
  }
}

.page-wrapper {
  transition: opacity 450ms ease;

  &--navigation {
    opacity: 0.4;
  }
}
</style>
