<template>
  <div
    v-if="!isCookieBotEnabled && resource && resource.columns"
    v-click-outside="enableTracking"
    class="cookie-consent"
    :class="{
      'cookie-consent--closed' : closed,
      'cookie-consent--hidden' : hidden
    }"
  >
    <div class="cookie-consent__content">
      <div
        class="cookie-consent__text"
      >
        <span
          v-if="resource.columns.consent"
          v-editable.text="resource.columns.consent"
        />
        <nuxt-link
          v-if="resource.columns.agree"
          v-editable.text="resource.columns.agree"
          class="cookie-consent__agree"
          :to="linkPath"
        />
      </div>
      <button
        v-if="resource.columns.close"
        v-editable.text="resource.columns.close"
        class="cookie-consent__close"
        @click="closeConsent"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import editable from '@i22/frontend-cms-utils/mixins/editable'
import linkPathMixin from '@/mixins/link-path'

export default {
  name: 'CookieConsent',
  directives: {
    ClickOutside
  },
  mixins: [ editable, linkPathMixin ],
  data () {
    return {
      closed: false,
      hidden: false,
      tracking: false
    }
  },
  computed: {
    isCookieBotEnabled () {
      return !this.$cms && process.env.COOKIEBOT_ID
    },
  },
  mounted () {
    this.setHidden()
  },
  methods: {
    closeConsent () {
      this.enableTracking()
      this.closed = true
      if (localStorage) { localStorage.setItem('bcCookieNotice', true) }
    },
    setHidden () {
      if (localStorage) { this.hidden = localStorage.getItem('bcCookieNotice') }
    },
    enableTracking () {
      if (!this.hidden && !this.tracking) {
        this.tracking = true
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.cookie-consent {
  z-index: 6;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-color: color(darkgreybg);
  transition:
    transform 250ms ease,
    opacity 250ms ease;

  &--closed {
    transform: translateY(100%);
    opacity: 0;
  }

  &--hidden {
    display: none;
  }

  animation-name: cookieAppear;
  animation-duration: 4.5s;
  animation-iteration-count: 1;

  @keyframes cookieAppear {
    0% { transform: translateY(100%); }
    80% { transform: translateY(100%); }
    100%   { transform: translateY(0); }
  }

  @include breakpoint(sm) {
    left: 0;
    width: calc(100% - 50px);
    height: 90px;
  }

  @include breakpoint(md) {
    left: 365px;
    width: calc(100% - 415px);
    height: 80px;
    background-color: rgba(color(primary), 0.8);
  }

  @include breakpoint(lg) {
    left: 415px;
    width: calc(100% - 465px);
  }

  &__agree {
    @include font-family(regular);
    @include cursorPointer();
    font-size: font-size(2);
    line-height: line-height(massive);
    display: inline;
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    color: color(light);
    text-decoration: underline;
    padding: 0;
    border: 0;
  }

  &__close {
    @include font-family(regular);
    @include cursorPointer();
    font-size: font-size(2);
    line-height: line-height(solid);
    letter-spacing: letter-spacing(loose);
    text-transform: uppercase;
    padding: 5px 0;
    display: block;
    -webkit-appearance: none;
    background: transparent;
    color: color(light);
    border: 0;
    border-bottom: 1px solid color(light);

    @include breakpoint(sm) {
      margin-left: 50px;
    }
  }

  &__content {
    @include font-family(regular);
    font-size: font-size(2);
    line-height: line-height(massive);
    color: color(light);
    padding: 20px 30px;

    @include breakpoint(sm) {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px 0 30px;
    }

    @include breakpoint(md) {
      padding: 0 20px;
    }

    @include breakpoint(lg) {
      padding: 0 30px;
    }
  }

  &__text {
    margin-bottom: 20px;

    @include breakpoint(sm) {
      margin-bottom: 0;
    }
  }
}
</style>
