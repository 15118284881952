<template>
  <div
    v-in-viewport.once
    class="info-box"
  >
    <div class="info-box__container">
      <div class="info-box__column">
        <h2
          v-if="resource.columns.headline"
          v-editable.text="resource.columns.headline"
          class="info-box__headline"
        />
        <div
          v-if="resource.columns.text"
          v-editable="resource.columns.text"
          class="info-box__paragraph"
        />
      </div>
      <div class="info-box__column-left">
        <div
          v-if="resource.columns.subline_left"
          v-editable.text="resource.columns.subline_left"
          class="info-box__subheadline"
        />
        <div
          v-editable="resource.columns.text_left"
          class="info-box__subparagraph"
        />
      </div>
      <div class="info-box__column-right">
        <div
          v-editable.text="resource.columns.subline_right"
          class="info-box__subheadline"
        />
        <div
          v-editable="resource.columns.text_right"
          class="info-box__subparagraph"
        />
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'InfoBox',
  mixins: [ editable ]
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.info-box {
  position: relative;
  z-index: 1;
  transition:
    opacity $transition-duration * 2 ease,
    transform $transition-duration * 2 ease;

  &.below-viewport {
    transform: translateY($animation-offset);
    opacity: 0;
  }

  &.in-viewport {
    transform: none;
    opacity: 1;
  }

  &__container {
    @include container();
 }

  &__column {
    @include column(12);
    @include breakpoint(md) {
      @include column(8);
      @include push(2);
    }
  }

  &__column-left {
    @include column(12);
    margin-bottom: 50px;

    @include breakpoint(md) {
      @include push(2);
      @include column(4);
      margin-bottom: 0;
    }
  }

  &__column-right {
    @include column(12);

    @include breakpoint(md) {
      @include column(4);
    }
  }

  &__headline {
    @include font-family(bold);
    text-transform: uppercase;
    font-size: font-size(6);
    line-height: line-height(standard);
    margin-bottom: 15px;

    @include breakpoint(sm) {
      font-size: font-size(7);
      letter-spacing: letter-spacing(airy);
    }

    @include breakpoint(lg) {
      margin-bottom: 40px;
      font-size: font-size(9);
    }
  }

  &__subheadline {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
    margin-bottom: 10px;
    border-bottom: 1px solid color(dark);
  }

  &__paragraph {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
    margin-bottom: 35px;
    p {
      margin-top: 0;
    }

    @include breakpoint(sm) {
      margin-bottom: 50px;
    }
  }

  &__subparagraph {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
    p {
      margin: 0;
    }
  }
}
</style>
