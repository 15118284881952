<template>
  <div class="accordion">
    <div class="accordion__container">
      <div class="accordion__column">
        <h2
          v-if="resource.columns.headline"
          v-editable.text="resource.columns.headline"
          v-in-viewport.once
          class="accordion__headline"
        />
        <AccordionItem
          v-for="child in children"
          :key="child.$id"
          :resource="child"
          :active="child.$id === activeChild"
          @activate="toggleActiveChild"
        />
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
import AccordionItem from '@/components/blocks/accordion-item'

export default {
  name: 'Accordion',
  components: {
    AccordionItem
  },
  mixins: [ editable ],
  data () {
    return {
      activeChild: null
    }
  },
  computed: {
    children () {
      if (!this.resource.resolveChildren()) { return [] }
      return this.resource.resolveChildren()
    }
  },
  methods: {
    toggleActiveChild (activeChild) {
      this.activeChild = this.activeChild !== activeChild ? activeChild : null
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.accordion {
  position: relative;
  z-index: 1;
  background-color: color(primary);
  cursor: url('~assets/icons/default_white_s.png') 7.5 7.5, move;
  cursor: -webkit-image-set(
    url('~assets/icons/default_white_s.png') 1x,
    url('~assets/icons/default_white_l.png') 2x
  ) 7.5 7.5, move;

  &__container {
    @include container();
    background-color: color(primary);
  }

  &__headline {
    @include font-family(bold);
    font-size: font-size(5);
    line-height: line-height(standard);
    text-transform: uppercase;
    color: color(light);
    letter-spacing: 3px;
    margin-bottom: 35px;
    transition:
      opacity $transition-duration * 2 ease,
      transform $transition-duration * 2 ease;

    &.below-viewport {
      transform: translateY($animation-offset);
      opacity: 0;
    }

    &.in-viewport {
      transform: none;
      opacity: 1;
    }

    @include breakpoint(sm) {
      font-size: font-size(7);
      letter-spacing: letter-spacing(airy);
    }

    @include breakpoint(lg) {
      font-size: font-size(9);
    }
  }

  &__column {
    @include column(12);
    padding: 45px 0 60px;

    @include breakpoint(sm) {
      @include column(10);
      @include push(1);
      padding: 90px 0 100px;
    }
  }
}
</style>
