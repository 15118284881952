<template>
  <nuxt-link
    :to="linkPath"
    class="image-teaser"
  >
    <div class="image-teaser__container">
      <div
        v-in-viewport.once
        class="image-teaser__image-column"
      >
        <img
          v-if="image && image.file"
          :src="image.file.url"
          :alt="image.alt"
          class="image-teaser__image"
        >
      </div>
      <div
        v-in-viewport.once
        :class="[
          { 'image-teaser__teaser-column--revert' : resource.columns.revert },
          `image-teaser__teaser-column--${color}`
        ]"
        class="image-teaser__teaser-column"
      >
        <div
          :class="color"
          class="image-teaser__teaser"
        >
          <h3
            v-if="resource.columns.headline"
            v-editable.text="resource.columns.headline"
            class="image-teaser__headline"
          />
          <div
            v-if="resource.columns.text"
            v-editable="resource.columns.text"
            class="image-teaser__text"
          />
          <div class="image-teaser__link-wrapper">
            <HyperLink
              :to="linkPath"
              :color="color"
              tag="span"
            >
              {{ resource.columns.cta }}
            </HyperLink>
          </div>
        </div>
        <div
          :class="`image-teaser__teaser-bg--${color}`"
          class="image-teaser__teaser-bg"
        />
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
import linkPathMixin from '@/mixins/link-path'
import HyperLink from '@/components/blocks/hyper-link'

export default {
  name: 'ImageTeaser',
  components: {
    HyperLink
  },
  mixins: [ editable, linkPathMixin ],
  computed: {
    color () {
      if (!this.resource.columns.color) { return null }
      return this.resource.columns.color
    },
    image () {
      const image = this.resource.columns.picture
      if (!this.resource.resolveById(image)) { return false }
      return this.resource.resolveById(image).columns
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';
$offset-bottom-tablet: 90px;
$offset-bottom-desktop: 60px;

.image-teaser {
  display: block;
  position: relative;
  text-decoration: none;
  z-index: 1;
  padding-bottom: $offset-bottom-tablet;

  @include breakpoint(lg) {
    padding-bottom: $offset-bottom-desktop;
  }

  &__container {
    position: relative;
    max-width: $max-container-width;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    &:hover {
      .image-teaser__teaser-bg {
        top: $gutter * 2;
        right: $gutter * 3;
      }

      .image-teaser__teaser-column {
        transform: translateY(-#{$gutter}) translateX(#{$gutter});
      }
    }
  }

  &__image-column {
    @include column(12);
    padding: 0;
    transition:
      opacity $transition-duration * 2 ease,
      transform $transition-duration * 2 ease;

    &.below-viewport {
      transform: translateY($animation-offset * 2);
      opacity: 0;
    }

    &.in-viewport {
      transform: none;
      opacity: 1;
    }

    @include breakpoint(xl) {
      @include column(12);
    }
  }

  &__teaser-column {
    position: relative;
    transition:
      opacity $transition-duration * 2 ease,
      transform $transition-duration * 2 ease;

    @include breakpoint(sm) {
      position: absolute;
      @include column(7);
      padding: 0;
      right: 10px;
      bottom: $offset-bottom-tablet * -1;

      &.below-viewport {
        transform: translateX($animation-offset);
        opacity: 0;
      }

      &.in-viewport {
        transform: none;
        opacity: 1;
      }

      &--revert {
        right: auto;
        left: 50px;

        &.below-viewport {
          transform: translateX(-1 * $animation-offset);
          opacity: 0;
        }

        &.in-viewport {
          transform: none;
          opacity: 1;
        }
      }
    }

    @include breakpoint(lg) {
      @include column(5);
      padding: 0;
      bottom: $offset-bottom-desktop * -1;
    }
  }

  &__teaser {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: color(greybg);
    z-index: 2;
    position: relative;

    @include breakpoint(sm) {
      padding: 30px 45px;
    }

    @include breakpoint(lg) {
      min-height: 490px;
      padding: 60px 140px 50px 90px;
    }

    &--beige {
      background-color: lighten(color(beige), 30%);
    }

    &--gold {
      background-color: lighten(color(lifestyle), 30%);
    }

    &--green {
      background-color: color(living-bright);
    }

    &--blue {
      background-color: lighten(color(business), 30%);
    }
  }

  &__teaser-bg {
    position: absolute;
    z-index: 0;
    width: calc(100% - #{$gutter});
    height: 100%;
    top: 0;
    right: 10px;
    transition:
      top $transition-duration * 2 ease,
      right $transition-duration * 2 ease;

    @include breakpoint(xl) {
      width: calc(100% - #{$gutter} * 2);
      right: $gutter * 3;
    }

    &--gold {
      background-color: color(lifestyle);
    }

    &--green {
      background-color: color(living);
    }

    &--blue {
      background-color: color(business);
    }

    &--beige {
      background-color: color(beige);
    }
  }

  &__headline {
    flex: 1;
    @include font-family(bold);
    font-size: font-size(8);
    line-height: line-height(solid);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    margin-bottom: 30px;

    @include breakpoint(sm) {
      font-size: font-size(10);
      margin-bottom: 20px;
    }

    @include breakpoint(lg) {
      font-size: font-size(12);
      margin-bottom: 40px;
    }
  }

  &__text {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(solid);
    margin-bottom: 30px;
  }

  &__image {
    display: block;
    width: 100%;
  }
}
</style>
