<template>
  <div
    :class="{'side-navigation--open' : open}"
    class="side-navigation"
  >
    <div class="side-navigation__menu-wrapper">
      <div
        :class="{'side-navigation__menu--hidden' : open}"
        class="side-navigation__menu"
        @click="toggleNav"
      >
        <span class="side-navigation__line" />
        <span class="side-navigation__line" />
        <span class="side-navigation__line" />
      </div>
      <CloseIcon
        :class="{'side-navigation__close--hidden' : !open}"
        class="side-navigation__close"
        @click="toggleNav"
      />
    </div>
    <div class="side-navigation__title">
      <nuxt-link
        v-if="resource && resource.homeSite"
        :to="resource.homeSite.url"
        class="side-navigation__title-link"
        v-text="resource.homeSite.columns.title"
      />
    </div>
    <div class="side-navigation__lang">
      <LanguageSwitch :resource="resource" />
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/assets/icons/close-button.svg'
import LanguageSwitch from '@/components/globals/navigation/language-switch'
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'SideNavigation',
  components: {
    LanguageSwitch,
    CloseIcon
  },
  mixins: [ editable ],
  props: {
    open: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    toggleNav () {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.side-navigation {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  width: 50px;
  text-align: center;
  z-index: 3;
  transition: border-left 1ms 700ms;

  @include breakpoint(sm) {
    background-color: color(light);
    height: 100%;
    border-left: 1px solid color(dark);
    animation-name: sidebarAppear;
    animation-duration: 2.5s;
    animation-iteration-count: 1;

    @keyframes sidebarAppear {
      0% { transform: translateX(100%); }
      80% { transform: translateX(100%); }
      100%   { transform: translateX(0); }
    }

    &--open {
      border-left: solid 1px rgba(0, 0, 0, 0);
      transition: none;
    }
  }

  &__menu-wrapper {
    flex: 1;
  }

  &__menu {
    @include cursorPointer();
    margin: 22px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 22px;
    height: 14px;
    transition:
      transform $transition-duration ease,
      opacity $transition-duration ease;

    &--hidden {
      opacity: 0;
      transform: rotate(45deg);
    }
  }

  &__close {
    @include cursorPointer();
    position: absolute;
    display: block;
    top: 20px;
    left: 15px;
    width: 20px;
    height: 20px;
    transition:
      transform $transition-duration ease,
      opacity $transition-duration ease;

    &--hidden {
      opacity: 0;
      transform: rotate(-90deg);
    }
  }

  &__line {
    background-color: color(dark);
    width: 100%;
    height: 2px;
  }

  &__title {
    @include font-family(light);
    display: none;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    word-break: keep-all;
    position: absolute;
    top: 50%;
    left: -145%;
    width: 191px;
    letter-spacing: 3px;
    transform: translateY(-50%) rotate(90deg);

    @include breakpoint(sm) {
      display: block;
    }
  }

  &__title-link {
    color: color(primary);
    text-decoration: none;
  }

  &__lang {
    display: none;
    margin-bottom: 15px;

    @include breakpoint(sm) {
      display: block;
    }
  }
}
</style>
