<template>
  <Component
    :is="tag"
    :to="to"
    class="hyper-link"
    :class="[
      `hyper-link--${color}`,
      { 'hyper-link--center' : center }
    ]"
  >
    <MoreArrowIcon
      class="hyper-link__icon"
      :class="`hyper-link__icon--${color}`"
    />
    <span
      class="hyper-link__text"
      :class="`hyper-link__text--${color}`"
    >
      <slot />
    </span>
  </Component>
</template>

<script>
import MoreArrowIcon from '@/assets/icons/more-arrow.svg'

export default {
  name: 'Link',
  components: {
    MoreArrowIcon
  },
  props: {
    to: {
      type: String,
      default: () => '/'
    },
    color: {
      type: String,
      default: 'black'
    },
    center: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'router-link'
    },
    cta: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.hyper-link {
  @include font-family(light);
  font-size: font-size(2);
  line-height: line-height(massive);
  letter-spacing: letter-spacing(airy);
  display: inline-block;
  overflow: hidden;
  padding-right: 45px;
  color: color(primary);
  position: relative;
  text-decoration: none;
  letter-spacing: 2.5px;
  text-transform: uppercase;

  &--center {
    margin-left: 22px;
  }

  &--blue {
    color: color(business);
  }

  &--green {
    color: color(living);
  }

  &--gold {
    color: color(lifestyle);
  }

  &--white {
    color: color(light);
  }

  &--black {
    color: color(dark);
  }

  &--grey {
    color: color(grey);
  }

  @include breakpoint(lg) {
    letter-spacing: letter-spacing(loose);
  }

  &:hover {
    .hyper-link__icon {
      transform: translateX(0) translateY(-50%);
    }

    .hyper-link__text {
      transform: translateX(45px);
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateX(-40px) translateY(-50%);
    left: 0;
    stroke: color(primary);
    width: 35px;
    margin-right: 10px;
    transition: transform $transition-duration ease;

    &--blue {
      fill: color(business);
      stroke: color(business);
    }

    &--green {
      fill: color(living);
      stroke: color(living);
    }

    &--gold {
      fill: color(lifestyle);
      stroke: color(lifestyle);
    }

    &--white {
      fill: color(light);
      stroke: color(light);

    }

    &--black {
      fill: color(dark);
      stroke: color(dark);
    }

    &--grey {
      fill: color(grey);
      stroke: color(grey);
    }
  }

  &__text {
    display: block;
    padding-bottom: 4px;
    border-bottom: 1px solid color(primary);
    transition: transform $transition-duration ease;

    &--blue {
      border-color: color(business);
    }

    &--green {
      border-color: color(living);
    }

    &--gold {
      border-color: color(lifestyle);
    }

    &--white {
      border-color: color(light);
    }

    &--grey {
      border-color: color(grey);
    }

    &--black {
      border-color: color(dark);
    }
  }
}
</style>
