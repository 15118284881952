<template>
  <div>
    <div ref="videostage" class="videostage">
      <div
        ref="video"
        class="videostage__wrapper"
      >
        <video
          v-if="video"
          :src="video"
          class="videostage__video"
          type="video/mp4"
          loop
          muted
          autoplay
        >
          Your browser does not support the video tag.
        </video>
        <div class="videostage__container">
          <div class="videostage__column">
            <h1
              v-if="resource.columns.topline"
              v-editable.text="resource.columns.topline"
              class="videostage__topline"
            />
            <div
              v-if="resource.columns.headline"
              v-editable="resource.columns.headline"
              class="videostage__headline"
            />
          </div>
        </div>
        <div
          v-if="resource.columns.cta"
          class="videostage__cta"
        >
          <ScrollIcon class="videostage__icon" />
          <span
            v-editable.text="resource.columns.cta"
          />
        </div>
      </div>
      <div ref="background" class="videostage__background" />
    </div>
    <div class="videostage__whitespace--m" />
  </div>
</template>

<script>
import ScrollIcon from '@/assets/icons/scroll-icon.svg'
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'VideoStage',
  components: {
    ScrollIcon
  },
  mixins: [ editable ],
  data () {
    return {
      fixedBackground: false
    }
  },
  computed: {
    video () {
      const video = this.resource.columns.video
      if (!video) { return null }
      return this.resource.resolveById(video).columns.file.url
    }
  },
  mounted () {
    if (process.browser && this.$refs.videostage) {
      window.addEventListener('scroll', this.toggleBackgroundClass, false)
      this.toggleBackgroundClass()
    }
  },
  beforeDestroy () {
    if (process.browser) {
      window.removeEventListener('scroll', this.toggleBackgroundClass, false)
    }
  },
  methods: {
    toggleBackgroundClass (e) {
      const stage = this.$refs.videostage
      const bg = this.$refs.background
      const topDistance = stage.getBoundingClientRect().top * -1
      const height = stage.clientHeight

      this.$refs.video.style.transform = `translateY(${window.scrollY * -0.3}px)`

      if (topDistance >= height && !this.fixedBackground) {
        this.fixedBackground = true
        bg.classList.add('videostage__background--fixed')
      }
      if (topDistance <= height && this.fixedBackground) {
        this.fixedBackground = false
        bg.classList.remove('videostage__background--fixed')
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.videostage {
  position: relative;
  z-index: 1;
  padding-top: 100vh;

  &__background {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: color(light);

    &--fixed {
      position: fixed;
      top: 0;
    }
  }

  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    overflow: hidden;
  }

  &__container {
    @include container();
  }

  &__column {
    @include column(12);
    margin-top: 50px;
    z-index: 2;
    animation-name: columnAppear;
    animation-duration: 2s;
    animation-iteration-count: 1;

    @keyframes columnAppear {
      0% { opacity: 0; }
      80% { opacity: 0; }
      100%   { opacity: 1; }
    }

    @include breakpoint(sm) {
      margin-top: 70px;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%);
    background-color: #eee;

    @media (min-aspect-ratio: 16/9) {
      height: auto;
      width: 100%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  &__topline {
    @include font-family(regular);
    font-size: font-size(4);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    color: color(dark);
    margin-bottom: 10px;
    margin-left: -15px;
    text-shadow: 0 0 20px hsla(0, 0%, 100%, 0.8);

    @include breakpoint(sm) {
      letter-spacing: letter-spacing(loose);
      margin-bottom: 20px;
      margin-left: 0;
    }
  }

  &__headline {
    @include font-family(bold);
    font-size: font-size(10);
    line-height: line-height(solid);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    color: color(dark);
    margin-left: -17px;
    text-shadow: 0px 0px 15px rgba(255,255,255,0.4);

    @include breakpoint(sm) {
      font-size: font-size(14);
      letter-spacing: letter-spacing(loose);
      margin-left: -0.05em;
    }

    @include breakpoint(lg) {
      font-size: font-size(15);
      line-height: line-height(tight);
      letter-spacing: letter-spacing(massive);
    }

    .link {
      text-decoration: none;
      transition: color 200ms ease;

      &:hover {
        color: #fff;
      }
    }

    p {
      margin: 0;
    }
  }

  &__cta {
    @include font-family(regular);
    font-size: font-size(4);
    line-height: line-height(massive);
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: color(light);
    padding: 25px 30px 26px 50px;
    animation-name: appear;
    animation-duration: 3s;
    animation-iteration-count: 1;

    @keyframes appear {
      0% { transform: translateY(100%) }
      85% { transform: translateY(100%) }
      100%   { transform: translateX(0) }
    }

    @include breakpoint(md) {
      display: block;
    }

    @include breakpoint(lg) {
      padding: 25px 60px 26px 70px;
    }
  }

  &__whitespace {
    width: 100%;
    background-color: color(light);

    &--s {
      padding-bottom: $spacing-unit;

      @include breakpoint(sm) {
        padding-bottom: $spacing-unit * 2;
      }
    }

    &--m {
      padding-bottom: $spacing-unit * 2;

      @include breakpoint(sm) {
        padding-bottom: $spacing-unit * 4;
      }
    }

    &--l {
      padding-bottom: $spacing-unit * 8;

      @include breakpoint(sm) {
        padding-bottom: $spacing-unit * 16;
      }
    }
  }

  &__icon {
    margin-right: 15px;
  }
}
</style>
