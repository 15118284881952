<template>
  <div
    v-in-viewport.once
    class="headline"
  >
    <div class="headline__container">
      <div class="headline__column">
        <h2
          v-if="resource.columns.content"
          v-editable.text="resource.columns.content"
          class="headline__content"
          :class="{'headline__content--centered' : resource.columns.centered}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'Headline',
  mixins: [ editable ]
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.headline {
  position: relative;
  z-index: 1;
  transition:
    opacity $transition-duration * 2 ease,
    transform $transition-duration * 2 ease;

  &.below-viewport {
    transform: translateY($animation-offset);
    opacity: 0;
  }

  &.in-viewport {
    transform: none;
    opacity: 1;
  }

  &__content {
    @include font-family(bold);
    font-size: font-size(8);
    line-height: line-height(solid);
    letter-spacing: letter-spacing(loose);
    text-transform: uppercase;

    &--centered {
      text-align: center;
    }

    @include breakpoint(sm) {
      font-size: font-size(10);
    }

    @include breakpoint(lg) {
      font-size: font-size(12);
    }
  }

  &__container {
    @include container();
  }

  &__column {
    @include column(12);
  }
}
</style>
