<template>
  <div
    v-in-viewport.once
    class="legal-text"
  >
    <div class="legal-text__container">
      <div class="legal-text__column">
        <h2
          v-if="resource.columns.headline"
          v-editable.text="resource.columns.headline"
          class="legal-text__headline"
        />
        <div
          v-if="resource.columns.text"
          v-editable="resource.columns.text"
          class="legal-text__paragraph"
        />
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'LegalText',
  mixins: [ editable ]
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.legal-text {
  position: relative;
  z-index: 1;
  transition:
    opacity $transition-duration * 2 ease,
    transform $transition-duration * 2 ease;

  &.below-viewport {
    transform: translateY($animation-offset);
    opacity: 0;
  }

  &.in-viewport {
    transform: none;
    opacity: 1;
  }

  &__container {
    @include container();
  }

  &__column {
    @include column(12);

    @include breakpoint(sm) {
      @include column(10);
      @include push(1);
    }

    @include breakpoint(md) {
      @include column(12);
      margin-left: 0;
    }
  }

  &__headline {
    @include font-family(bold);
    font-size: font-size(8);
    line-height: line-height(solid);
    letter-spacing: letter-spacing(loose);
    text-transform: uppercase;
    margin-bottom: 30px;

    @include breakpoint(sm) {
      font-size: font-size(10);
    }

    @include breakpoint(lg) {
      font-size: font-size(12);
    }
  }

  &__paragraph {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);

    @include breakpoint(sm) {
      font-size: font-size(4);
    }

    @include breakpoint(md) {
      column-count: 2;
      column-gap: 30px;
    }

    p {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }
}
</style>
