<template>
  <div
    v-in-viewport.once
    class="large-text"
  >
    <div class="large-text__container">
      <div class="large-text__column">
        <div
          v-if="resource.columns.topline"
          v-editable.text="resource.columns.topline"
          class="large-text__topline"
        />
        <div
          v-if="resource.columns.paragraph"
          v-editable="resource.columns.paragraph"
          class="large-text__paragraph"
        />
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'LargeText',
  mixins: [ editable ]
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.large-text {
  position: relative;
  z-index: 1;
  transition:
    opacity $transition-duration * 2 ease,
    transform $transition-duration * 2 ease;

  &.below-viewport {
    transform: translateY($animation-offset);
    opacity: 0;
  }

  &.in-viewport {
    transform: none;
    opacity: 1;
  }

  &__container {
    @include container();
  }

  &__column {
    @include column(12);

    @include breakpoint(md) {
      @include column(10);
      @include push(1);
    }
  }

  &__topline {
    @include font-family(light);
    font-size: font-size(1);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    margin-bottom: 10px;

    @include breakpoint(sm) {
      margin-bottom: 15px;
      font-size: font-size(2);
      letter-spacing: letter-spacing(loose);
    }

    @include breakpoint(lg) {
      margin-bottom: 20px;
    }
  }

  &__paragraph {
    @include font-family(light);
    font-size: font-size(5);
    line-height: line-height(standard);

    @include breakpoint(sm) {
      font-size: font-size(8);
    }
    @include breakpoint(lg) {
      font-size: font-size(9);
    }
  }
}
</style>
