<template>
  <div class="navigation">
    <SideNavigation
      :resource="resource"
      :open="open"
      @toggle="handleNav"
    />
    <NavigationOverlay
      :resource="resource"
      :open="open"
      @close="handleNav"
    />
    <div v-if="open" class="navigation__close" @click="handleNav" />
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
import SideNavigation from '@/components/globals/navigation/side-navigation'
import NavigationOverlay from '@/components/globals/navigation/navigation-overlay'
export default {
  name: 'Navigation',
  components: {
    SideNavigation,
    NavigationOverlay
  },
  mixins: [ editable ],
  props: {
    open: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    handleNav () {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="scss">
.navigation {
  &__close {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 60px;
  }
}
</style>
