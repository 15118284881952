<template>
  <nuxt-link
    v-if="getPage()"
    :to="getPage()"
    data-cms-link
    class="language-switch__link-wrapper"
  >
    <div
      v-for="lang in languages"
      :key="lang.slug"
    >
      <div
        :class="{'language-switch__link--active' : lang.slug === activeLanguage}"
        class="language-switch__link"
        v-text="lang.title"
      />
    </div>
  </nuxt-link>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
export default {
  name: 'LanguageSwitch',
  mixins: [ editable ],
  data () {
    return {
      languages: [
        {
          title: 'DE',
          slug: 'de'
        },
        {
          title: 'EN',
          slug: 'en'
        }
      ]
    }
  },
  computed: {
    activeLanguage () {
      if (!this.resource) { return false }
      return this.resource.currentLanguage.columns.language
    }
  },
  methods: {
    getPage () {
      if (!this.resource) { return false }
      const switchLang = this.resource.currentLanguage.columns.language === 'en' ? 'de' : 'en'
      const currentLanguagePath = this.resource.currentLanguage.path
      if (!this.resource.getResourceByUrl(this.$route.path)) { return this.resource.getResourceByUrl(`/${switchLang}`).url }
      const currentPagePath = this.resource.getResourceByUrl(this.$route.path).path
      const newLanguagePath = this.resource.languagePath(switchLang)
      const newPath = currentPagePath.replace(currentLanguagePath, newLanguagePath)
      return this.resource.getPageByPath(newPath) ? this.resource.getPageByPath(newPath).url : false
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.language-switch {

  &__link-wrapper {
    color: color(grey);
    text-decoration: none;

    &:hover {
      color: color(primary);
    }
  }

  &__link {
    @include font-family(regular);
    display: block;
    letter-spacing: letter-spacing(airy);
    font-size: font-size(1);

    &--active {
      color: color(primary)
    }
  }
}
</style>
