<template>
  <div class="gallery-small">
    <div class="gallery-small__container">
      <div
        class="gallery-small__left-column"
        :class="{ 'gallery-small__left-column--reverse' : resource.columns.reverse }"
      >
        <img
          v-if="imageLeft"
          :src="`${imageLeft.file.url}`"
          :alt="imageLeft.alt"
          class="gallery-small__image"
        >
        <div
          v-if="imageLeft && resource.columns.show_description"
          class="gallery-small__description"
          v-text="imageLeft.description"
        />
      </div>
      <div
        class="gallery-small__right-column"
        :class="{ 'gallery-small__right-column--reverse' : resource.columns.reverse }"
      >
        <img
          v-if="imageRight"
          :src="`${imageRight.file.url}`"
          :alt="imageRight.alt"
          class="gallery-small__image gallery-small__image--second"
        >
        <div
          v-if="imageRight && resource.columns.show_description"
          class="gallery-small__description"
          v-text="imageRight.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'GallerySmall',
  mixins: [ editable ],
  computed: {
    imageLeft () {
      const image = this.resource.columns.image_left
      if (!this.resource.resolveById(image)) { return null }
      return this.resource.resolveById(image).columns
    },
    imageRight () {
      const image = this.resource.columns.image_right
      if (!this.resource.resolveById(image)) { return null }
      return this.resource.resolveById(image).columns
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

$image-offset: 5%;

.gallery-small {
  position: relative;
  z-index: 1;

  &__container {
    @include container();
  }

  &__left-column {
    @include column(12);
    margin-bottom: 70px;

    @include breakpoint(sm) {
      @include column(11);
    }

    @include breakpoint(md) {
      @include column(6);
      padding: 0 $image-offset;
      margin-bottom: 0;

      &--reverse {
        padding: $image-offset * 1.3 0 0 $image-offset;
      }
    }
  }

  &__right-column {
    @include column(12);
    position: relative;

    @include breakpoint(sm) {
      @include column(11);
      @include push(1);
    }

    @include breakpoint(md) {
      @include column(6);
      margin-left: 0;
      display: flex;
      flex-direction: column;
      padding: $image-offset * 1.3  $image-offset 0 0;

      &--reverse {
        padding: 0 $image-offset;
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__description {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
    margin-top: 5px;
  }
}
</style>
