<template>
  <div
    class="list-element__wrapper"
  >
    <nuxt-link
      :to="linkPath"
      :class="`list-element--${color}`"
      class="list-element"
    >
      <div class="list-element__left">
        <div
          v-if="resource.columns.title"
          class="list-element__title"
        >
          <span
            v-editable.text="resource.columns.title"
          />
        </div>
        <div
          v-if="resource.columns.text"
          v-editable.text="resource.columns.text"
          class="list-element__content"
        />
      </div>
      <div class="list-element__right">
        <div
          :to="linkPath"
          :class="`list-element__link--${color}`"
          class="list-element__link"
        >
          {{ cta }}
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<script>

import editable from '@i22/frontend-cms-utils/mixins/editable'
import linkPathMixin from '@/mixins/link-path'

export default {
  name: 'ListElement',
  mixins: [ editable, linkPathMixin ],
  props: {
    resource: {
      type: Object,
      required: true
    },
    cta: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'gold'
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.list-element {
  display: block;
  border-top: 1px solid color(primary);
  padding: 30px 0;
  text-decoration: none;
  color: inherit;
  transition:
    background-color $transition-duration ease,
    padding-left $transition-duration ease,
    color $transition-duration ease;

  @include breakpoint(md) {
    padding: 40px 0;
    display: flex;
    align-items: center;
  }

  &:hover {
    color: color(light);
    padding-left: 30px;

    .list-element__link {
      color: color(light);
      border-color: color(light);
    }
  }

  &--blue:hover {
    background-color: color(business);
  }

  &--beige:hover {
    background-color: color(beige);
  }

  &--gold:hover {
    background-color: color(lifestyle);
  }

  &--green:hover {
    background-color: color(living);
  }

  &__wrapper {
    &:last-child {
      border-bottom: 1px solid color(primary);
    }
  }

  &__left {
    margin-bottom: 30px;

    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }

  &__right {
    @include breakpoint(md) {
      align-self: flex-end;
      flex: 1;
      min-width: 40%;
      text-align: right;
    }
  }

  &__link {
    @include font-family(light);
    font-size: font-size(2);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(airy);
    display: inline-block;
    overflow: hidden;
    margin-right: 45px;
    color: color(primary);
    position: relative;
    text-decoration: none;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: #fff;
    padding-bottom: 4px;
    border-bottom: 1px solid color(primary);
    transition:
      border-color $transition-duration ease,
      color $transition-duration ease;

    &--blue {
      color: color(business);
      border-color: color(business);
    }

    &--beige {
      color: color(beige);
      border-color: color(beige);
    }

    &--gold {
      color: color(lifestyle);
      border-color: color(lifestyle);
    }

    &--green {
      color: color(living);
      border-color: color(living);
    }
  }

  &__title {
    @include font-family(regular);
    position: relative;
    text-transform: uppercase;
    font-size: font-size(6);
    line-height: line-height(massive);
    letter-spacing: 3px;

    @include breakpoint(sm) {
      font-size: font-size(7);
    }

    @include breakpoint(lg) {
      font-size: font-size(9);
    }
  }

  &__content {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
  }
}

.layout a.list-element {
  cursor: url('~assets/icons/pointer_white_s.png') 9.5 9.5, move;
  cursor: -webkit-image-set(url('~assets/icons/pointer_white_s.png') 1x, url('~assets/icons/pointer_white_l.png') 2x) 9.5 9.5, move;
}
</style>
