<template>
  <div class="section-teaser">
    <div
      v-in-viewport.once
      class="section-teaser__container"
    >
      <SectionTeaserElement
        v-for="child in children"
        :key="child.$id"
        :resource="child"
        :one-child="children.length === 1"
        :class="{'section-teaser__column--fullwidth' : children.length === 1}"
        class="section-teaser__column"
      />
    </div>
  </div>
</template>

<script>
import { Resource } from '@/models'
import editable from '@i22/frontend-cms-utils/mixins/editable'
import SectionTeaserElement from '@/components/blocks/section-teaser-element'

export default {
  name: 'SectionTeaser',
  components: {
    SectionTeaserElement
  },
  mixins: [ editable ],
  models: [ Resource ],
  computed: {
    children () {
      if (!this.resource.resolveChildren()) { return [] }
      return this.resource.resolveChildren()
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.section-teaser {
  position: relative;
  z-index: 1;

  &__container {
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
      flex-direction: row;
    }
  }

  &__column {
    position: relative;
    width: 100%;
    height: 140px;
    overflow: hidden;
    background-color: #000;

    @include breakpoint(sm) {
      height: 310px;
    }

    @include breakpoint(md) {
      height: 530px;

      &--fullwidth {
        height: 290px;
      }
    }
  }
}
</style>
