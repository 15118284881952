<template>
  <div
    v-if="resource"
    :class="{'navigation-overlay--open' : open}"
    class="navigation-overlay"
  >
    <div class="navigation-overlay__scroll">
      <nuxt-link
        v-if="resource && resource.homeSite"
        :to="resource.homeSite.url"
        class="navigation-overlay__headline"
        v-text="resource.homeSite.columns.title"
      />
      <div class="navigation-overlay__container">
        <nuxt-link
          v-for="page in resource.mainCategories"
          :key="page.id"
          :to="page.url"
          :class="{ 'navigation-overlay__column--open' : open }"
          class="navigation-overlay__column"
          @click.native="handleClose"
        >
          <div
            :class="`navigation-overlay__image-wrapper--${page.columns.color}`"
            class="navigation-overlay__image-wrapper"
          >
            <img
              v-if="page.columns.image"
              :src="image(page.columns.image)"
              :alt="page.columns.title"
              class="navigation-overlay__image"
            >
          </div>

          <div
            :class="{'navigation-overlay__topline--grey' : page.columns.title === 'Background'}"
            class="navigation-overlay__topline"
          >
            {{ page.columns.topline }}
          </div>
          <div
            :class="{'navigation-overlay__title--grey' : page.columns.title === 'Background'}"
            class="navigation-overlay__title"
          >
            {{ page.columns.title }}
          </div>
          <MoreArrow
            :class="`navigation-overlay__svg--${page.columns.color}`"
            class="navigation-overlay__svg"
          />
        </nuxt-link>
      </div>
      <div class="navigation-overlay__info">
        <ul
          :class="{ 'navigation-overlay__list--open' : open }"
          class="navigation-overlay__list"
        >
          <li v-if="resource && resource.homeSite" class="navigation-overlay__element">
            <nuxt-link
              :to="resource.homeSite.url"
              class="navigation-overlay__link"
            >
              Home
            </nuxt-link>
          </li>
          <li
            v-for="site in resource.secondaryCategories"
            :key="site.id"
            class="navigation-overlay__element"
          >
            <nuxt-link
              :to="site.url"
              class="navigation-overlay__link"
              v-text="site.columns.title"
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="navigation-overlay__lang">
      <LanguageSwitch :resource="resource" />
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
import MoreArrow from '@/assets/icons/more-arrow.svg'
import LanguageSwitch from '@/components/globals/navigation/language-switch'
export default {
  name: 'NavigationOverlay',
  components: {
    LanguageSwitch,
    MoreArrow
  },
  mixins: [ editable ],
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClose () {
      if (this.open) {
        this.$emit('close')
      }
    },
    image (path) {
      const mediaResource = this.resource.resolveById(path)
      if (mediaResource && mediaResource.columns.file) { return mediaResource.columns.file.url }
      return ''
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.navigation-overlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  padding-right: 50px;
  background-color: #fff;
  border-left: 1px solid color(dark);
  transition: transform $transition-duration * 2 ease 150ms;
  transform: translateX(100%);
  z-index: 2;

  @include breakpoint(sm) {
    width: 90%;
    overflow-y: scroll;
  }

  &--open {
    transform: translateX(0);
    transition: transform $transition-duration * 2 ease;
  }

  &__scroll {
    padding-top: 30px;
    overflow-y: scroll;

    @include breakpoint(sm) {
      overflow-y: auto;
      padding-top: 0;
    }
  }

  &__container {
    max-width: $max-container-width;
    margin: 50px 0 0 20px;

    @include breakpoint(sm) {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      margin: 50px 25px 0;
    }
  }

  &__column {
    @include column(12);
    text-decoration: none;
    color: color(primary);
    margin-bottom: 30px;
    opacity: 0;
    transition: opacity 700ms ease;

    &--open {
      opacity: 1;

      &:nth-child(2) {
        transition-delay: 250ms;
      }

      &:nth-child(3) {
        transition-delay: 500ms;
      }

      &:nth-child(4) {
        transition-delay: 750ms;
      }
    }

    @include breakpoint(sm) {
      @include column(6);
    }

    @include breakpoint(lg) {
      @include column(3);
      height: max-content;
    }
  }

  &__close-wrapper {
    flex: 1;
  }

  &__lang {
    position: fixed;
    bottom: 30px;
    right: 20px;

    @include breakpoint(sm) {
      display: none;
    }
  }

  &__close {
    @include cursorPointer();
    display: block;
    margin: 20px auto;
    width: 20px;
    height: 20px;
  }

  &__image-wrapper {
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;

    @include breakpoint(sm) {
      margin-bottom: 10px;
    }

    @include breakpoint(lg) {
      margin-bottom: 30px;
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 30px;
    }

    &--gold {
      &::after {
        background-color: color(lifestyle);
      }
    }

    &--green {
      &::after {
        background-color: color(living);
      }
    }

    &--blue {
      &::after {
        background-color: color(business);
      }
    }

    &--beige {
      &::after {
        background-color: color(beige);
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
    transition: transform $transition-duration ease;

    @include breakpoint(lg) {
      height: 60vh;
      max-height: 500px;
      width: auto;
      transform: scale(1.08);
    }

    &:hover {
      transform: scale(1.1);
      opacity: 0.7;

      @include breakpoint(lg) {
        transform: scale(1.18);
      }
    }
  }

  &__headline {
    @include font-family(light);
    font-size: font-size(3);
    letter-spacing: letter-spacing(massive);
    text-transform: uppercase;
    padding: 25px 0 0 30px;
    text-decoration: none;

    @include breakpoint(sm) {
      display: none;
    }
  }

  &__topline {
    @include font-family(regular);
    font-size: font-size(1);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    margin-bottom: 5px;

    @include breakpoint(sm) {
      font-size: font-size(2);
    }

    @include breakpoint(lg) {
      letter-spacing: letter-spacing(loose);
      margin-bottom: 0;
    }

    &--grey {
      color: color(grey);
    }
  }

  &__title {
    @include font-family(bold);
    font-size: font-size(6);
    line-height: line-height(standard);
    letter-spacing: letter-spacing(solid);
    text-transform: uppercase;

    &--grey {
      color: color(grey);
    }

    @include breakpoint(sm) {
      font-size: font-size(7);
      letter-spacing: letter-spacing(airy);
    }

    @include breakpoint(lg) {
      font-size: font-size(9);
      letter-spacing: letter-spacing(loose);
      margin-bottom: 10px;

      &--grey {
        font-size: font-size(7);
      }
    }
  }

  &__title-link {
    color: color(primary);
    text-decoration: none;
  }

  &__link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__svg {
    display: none;

    @include breakpoint(lg) {
      display: block;
    }

    &--gold {
      stroke: color(lifestyle);
    }

    &--green {
      stroke: color(living);
    }

    &--blue {
      stroke: color(business);
    }
  }

  &__list {
    @include font-family(regular);
    list-style: none;
    margin-bottom: 30px;
    margin-left: 30px;
    padding-left: 0;
    opacity: 0;
    transition: opacity 300ms ease 100ms;

    &--open {
      transition: opacity 700ms ease 1000ms;
      opacity: 1;
    }

    @include breakpoint(sm) {
      margin-left: 35px;
    }
  }

  &__element {
    line-height: line-height(loose);

    @include breakpoint(sm) {
      display: inline-block;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
