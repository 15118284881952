<template>
  <div
    v-if="resource.columns"
    class="section-teaser-element"
  >
    <nuxt-link
      :to="linkPath"
      class="section-teaser-element__link"
    >
      <img
        v-if="image"
        :src="image.file.url"
        :alt="resource.columns.title"
        :class="{'section-teaser-element__image--fullwidth' : oneChild}"
        class="section-teaser-element__image"
      >
      <div class="section-teaser-element__info">
        <div
          v-if="resource.columns.topline"
          v-editable.text="resource.columns.topline"
          class="section-teaser-element__headline"
        />
        <div
          v-if="resource.columns.title"
          v-editable.text="resource.columns.title"
          :class="{'section-teaser-element__title--fullwidth' : oneChild}"
          class="section-teaser-element__title"
        />
        <div
          v-if="resource.columns.cta"
          v-editable.text="resource.columns.cta"
          class="section-teaser-element__cta"
        />
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
import linkPathMixin from '@/mixins/link-path'
import { Resource } from '@/models'

export default {
  name: 'SectionTeaserElement',
  mixins: [ editable, linkPathMixin ],
  props: {
    resource: {
      type: Object,
      required: true
    },
    oneChild: {
      type: Boolean,
      required: true
    }
  },
  models: [ Resource ],
  computed: {
    image () {
      const image = this.resource.columns.image
      if (!this.resource.resolveById(image)) { return null }
      return this.resource.resolveById(image).columns
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.section-teaser-element {
  &:hover {
    .section-teaser-element__image {
      transform: translateY(-50%) translateX(-50%) scale(1.1);
      opacity: 0.7;
    }
  }

  &__info {
    @include cursorPointer();
    display: flex;
    position: relative;
    height: 100%;
    flex-direction: column;
    z-index: 2;
    text-align: center;
  }

  &__headline {
    @include font-family(light);
    font-size: font-size(2);
    line-height: line-height(solid);
    display: none;
    color: color(light);
    text-transform: uppercase;
    flex: 1;
    margin-top: 10px;
    letter-spacing: 3px;

    @include breakpoint(sm) {
      display: block;
      margin-top: 20px;
    }

    @include breakpoint(md) {
      font-size: font-size(4);
    }
  }

  &__title {
    @include font-family(bold);
    font-size: font-size(10);
    line-height: line-height(solid);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    margin: 30px 0;
    color: color(light);
    text-transform: uppercase;
    letter-spacing: 2px;

    @include breakpoint(sm) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      margin: 0;
      font-size: font-size(7);

      &--fullwidth {
        font-size: font-size(11);
      }
    }

    @include breakpoint(md) {
      font-size: font-size(12);
      letter-spacing: letter-spacing(loose);
    }
  }

  &__cta {
    @include font-family(regular);
    font-size: font-size(2);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(airy);
    color: color(light);
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 30px;
    letter-spacing: 3px;

    @include breakpoint(lg) {
      letter-spacing: letter-spacing(loose);
    }
  }

  &__link {
    text-decoration: none;
  }

  &__image {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    opacity: 0.5;
    transition:
      opacity $transition-duration ease,
      transform $transition-duration ease;

    @include breakpoint(sm) {
      width: auto;
      height: 100%;

      &--fullwidth {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
