<template>
  <div class="layout">
    <div
      class="page-wrapper"
      :class="{ 'page-wrapper--navigation' : navigationIsOpen }"
    >
      <div class="error">
        <div class="error__wrapper">
          <div class="error__container">
            <div class="error__column">
              <h1
                class="error__headline"
                v-text="error.statusCode"
              />
              <div
                class="error__topline"
                v-text="error.message"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer :resource="getResource('Footer')" />
    </div>
    <Navigation
      :open="navigationIsOpen"
      :resource="getResource('Navigation')"
      @toggle="toggleNav"
    />
  </div>
</template>

<script>
import Footer from '@/components/globals/footer'
import Navigation from '@/components/globals/navigation'
import { Resource } from '@/models'
export default {
  components: {
    Footer,
    Navigation
  },
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      navigationIsOpen: false
    }
  },
  methods: {
    getResource (resourceClass) {
      const resource = Resource.query()
        .where('resource_class_name', resourceClass)
        .where(r => r.path.startsWith(`/${this.error.lang}`))
        .first()
      return resource
    },
    toggleNav () {
      this.navigationIsOpen = !this.navigationIsOpen
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.error {
  &__wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: color(light);
    background-image: url('~assets/404-header.jpg');
  }

  &__container {
    @include container();

    @include breakpoint(xl) {
      width: 100%;
    }
  }

  &__column {
    @include column(12);
    display: flex;
    flex-direction: column;
    z-index: 2;
    height: 100vh;
  }

  &__topline {
    @include font-family(light);
    font-size: font-size(5);
    line-height: line-height(massive);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    margin-bottom: 50px;

    @include breakpoint(sm) {
      font-size: font-size(6);
      letter-spacing: letter-spacing(loose);
      width: 60%;
      margin-bottom: 100px;
      margin-left: 0;
    }
  }

  &__headline {
    @include font-family(bold);
    font-size: font-size(9);
    line-height: line-height(solid);
    letter-spacing: letter-spacing(airy);
    text-transform: uppercase;
    padding-top: 50px;
    text-shadow: 0px 0px 15px rgba(255,255,255,0.1);
    flex: 1;

    @include breakpoint(sm) {
      padding-top: 100px;
      font-size: font-size(14);
      letter-spacing: letter-spacing(loose);
      margin-left: 0;
    }

    @include breakpoint(lg) {
      font-size: font-size(15);
      line-height: line-height(tight);
      letter-spacing: letter-spacing(massive);
    }
  }
}
</style>
