<template>
  <div
    v-in-viewport.once
    class="list"
  >
    <div class="list__container">
      <div class="list__column">
        <h2
          v-if="resource.columns.headline"
          v-editable.text="resource.columns.headline"
          class="list__headline"
        />
        <ListElement
          v-for="child in children"
          :key="child.$id"
          :resource="child"
          :color="resource.columns.color"
          :cta="resource.columns.cta"
        />
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'
import ListElement from '@/components/blocks/list-element'

export default {
  name: 'List',
  components: {
    ListElement
  },
  mixins: [ editable ],
  computed: {
    children () {
      if (!this.resource.resolveChildren()) { return [] }
      return this.resource.resolveChildren()
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.list {
  position: relative;
  z-index: 1;
  transition:
    opacity $transition-duration * 2 ease,
    transform $transition-duration * 2 ease;

  &.below-viewport {
    transform: translateY($animation-offset);
    opacity: 0;
  }

  &.in-viewport {
    transform: none;
    opacity: 1;
  }

  &__container {
    @include container();
  }

  &__headline {
    @include font-family(bold);
    font-size: font-size(8);
    line-height: line-height(standard);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    margin-bottom: 90px;

    @include breakpoint(sm) {
      font-size: font-size(10);
      letter-spacing: letter-spacing(airy);
      margin-bottom: 55px;
    }

    @include breakpoint(lg) {
      font-size: font-size(12);
      margin-bottom: 90px;
    }
  }

  &__column {
    @include column(12);

    @include breakpoint(md) {
      @include column(10);
      @include push(1);
    }
  }
}
</style>
