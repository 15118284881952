<template>
  <div class="gallery">
    <div class="gallery__container">
      <div
        v-in-viewport.once
        class="gallery__left-column"
      >
        <img
          v-if="imageLeft"
          :src="`${imageLeft.file.url}`"
          :alt="imageLeft.alt"
          class="gallery__image"
        >
        <div
          v-if="imageLeft && resource.columns.show_description"
          class="gallery__description"
          v-text="imageLeft.description"
        />
      </div>
      <div
        v-in-viewport.once
        class="gallery__right-column"
      >
        <img
          v-if="imageRight"
          :src="`${imageRight.file.url}`"
          :alt="imageRight.alt"
          class="gallery__image gallery__image--second"
        >
        <div
          v-if="imageRight && resource.columns.show_description"
          class="gallery__description"
          v-text="imageRight.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import editable from '@i22/frontend-cms-utils/mixins/editable'

export default {
  name: 'Gallery',
  mixins: [ editable ],
  computed: {
    imageLeft () {
      const image = this.resource.columns.image_left
      if (!this.resource.resolveById(image)) { return null }
      return this.resource.resolveById(image).columns
    },
    imageRight () {
      const image = this.resource.columns.image_right
      if (!this.resource.resolveById(image)) { return null }
      return this.resource.resolveById(image).columns
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/ui.scss';

.gallery {
  position: relative;
  z-index: 1;

  &__container {
    @include container();
  }

  &__left-column {
    @include column(12);
    margin-bottom: 50px;
    transition:
      opacity $transition-duration * 2 ease,
      transform $transition-duration * 2 ease;

    &.below-viewport {
      transform: translateY($animation-offset);
      opacity: 0;
    }

    &.in-viewport {
      transform: none;
      opacity: 1;
    }

    @include breakpoint(sm) {
      @include column(5);
      @include push(1);
      margin-bottom: 0;
    }
  }

  &__right-column {
    @include column(12);
    position: relative;
    transition:
      opacity $transition-duration * 2 ease $transition-duration / 2,
      transform $transition-duration * 2 ease $transition-duration / 2;

    &.below-viewport {
      transform: translateY($animation-offset);
      opacity: 0;
    }

    &.in-viewport {
      transform: none;
      opacity: 1;
    }

    @include breakpoint(sm) {
      @include column(4);
      @include push(1);
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__description {
    @include font-family(light);
    font-size: font-size(4);
    line-height: line-height(massive);
    margin-top: 5px;
  }
}
</style>
